import classes from './OurTeam.module.scss';
import clsx from 'clsx';
import { decodeHtmlEntities } from 'tools/utils';

interface OurTeamProps {
  data: '';
  title: string;
  noBackground?: boolean;
}

export const OurTeam = ({ data, title, noBackground }: OurTeamProps) => (
  <div className={clsx(classes.wrapper, noBackground && classes.wrapper_noBg)}>
    <div className={classes.title}>{title}</div>
    <div dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(data) }} />
  </div>
);
