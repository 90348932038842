import { useFavorites } from 'hooks';
import { useHotDeals } from 'hooks/useHotDeals';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DiscountType, SolutionType } from 'types/solutions';
import { useNavigate } from 'react-router-dom';

export const useProductCard = (
  data: SolutionType,
  currentPage?: number,
  setCurrentPage?: (val: number) => void,
  setProducts?: Dispatch<SetStateAction<SolutionType[]>>
) => {
  const [isFavorite, setIsFavorite] = useState(data?.is_favorite);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { getHotDealText } = useHotDeals(t);
  const changeFavorites = useFavorites(
    Number(data.id),
    isFavorite,
    setIsFavorite,
    currentPage,
    setCurrentPage,
    setProducts
  );

  const [hotDealDiscountText, setHotDealDiscountText] = useState('');

  const deals = data.discounts.find(
    (item: DiscountType) => item.type === 'HOT_DEAL'
  );

  const dateDeal = {
    dayEnd: 0,
    monthEnd: '',
    yearEnd: 0,
    active: false,
  };

  if (deals) {
    const utcDate = new Date(`${deals.deal_end_date}`);
    dateDeal.dayEnd = utcDate.getDate();
    dateDeal.monthEnd = utcDate.toLocaleString(i18n.language, {
      month: 'long',
    });
    dateDeal.yearEnd = utcDate.getFullYear();
    dateDeal.active = true;
  }

  const checkDiscount =
    data?.has_active_discounts &&
    Number(data.cost) !== Number(data.cost_with_discounts)
      ? Number(data.cost_with_discounts)
      : Number(data.cost);

  useEffect(() => {
    if (deals) setHotDealDiscountText(getHotDealText(deals, data));
  }, [deals, data]);

  const handleNavigateToShowcase = (slug: string) => {
    if (slug?.length) {
      navigate(`showcase/${slug}/`);
    }
  };

  return {
    deals,
    t,
    hotDealDiscountText,
    i18n,
    dateDeal,
    checkDiscount,
    changeFavorites,
    isFavorite,
    handleNavigateToShowcase,
  };
};
