import clsx from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'components/shared/Button';
import { Grid, GridItem } from 'components/common/Grid';
import { ModalMobile } from 'components/shared/ModalMobile';
import Table from 'components/shared/Table';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { LABELS } from 'constants/onboarding';
import { EmptyItems } from 'containers/EmptyItems';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { observer } from 'mobx-react-lite';
import {
  completeOnboarding,
  findOnboardingSteps,
  formatSteps,
  OnboardingSteps,
} from 'tools/onboarding';
import { RUSSIAN_LOCALE } from 'utils';
import 'containers/CardsContainer/IntroTooltip.css';
import { Filters } from './Filters';
import classes from './Finances.module.scss';
import { formatBalanceForLocale } from './formatBalanceForLocale';
import { FormPaymentBill } from './FormPaymentBill';
import { FormTopUpBalance } from './FormTopUpBalance';
import HistoryOperation from './HistoryOperation';
import { tableData } from './invoiceData';
import Invoices from './Invoices';
import { useFinancesContainer } from './useFinancesContainer';
import { useTopUpBalance } from './useTopUpBalance';
import { FinanceConditions } from 'types';
import Acts from './Acts';
import BonusCount from 'components/shared/BonusCount/BonusCount';

export const FinancesContainer = observer(() => {
  const {
    userStore,
    handleCloseTopUpModal,
    t,
    handleShowTopUpModal,
    showTopUpModal,
    scrollPosition,
    activeTab,
    setActivTab,
    tabs,
    internalInvoices,
    handleClosePaymentBillModal,
    showPaymentBillModal,
    handleShowPaymentBillModal,
    filter,
    itemsPerPage,
    getDataByPage,
    customPageCount,
    tableActive,
    columns,
    getDataByFilter,
    cancelledInvoices,
    toPayInvoices,
    paidInvoices,
    allInvoices,
  } = useFinancesContainer();

  const { GOT_IT, NEXT, BACK } = LABELS(t);
  const { isMediaTablet } = useWindowWidth();
  const isMobile = useWindowWidth().isMediaTablet;

  const { handleTopUpBalance, value, setValue } = useTopUpBalance(
    handleCloseTopUpModal
  );

  const isStaff = userStore?.user?.is_staff;
  const currency = userStore?.user?.currency?.char_code;
  const currentCurrencySymbol = userStore?.user?.currency?.symbol;
  const balanceValue =
    userStore?.user?.available_real_balance?.find(
      (i: FinanceConditions) => i?.currency?.char_code === 'RUB'
    )?.amount || (0 as number);
  const balanceBonus = userStore?.user?.available_bonus_balance;

  const [onboarding, setOnboarding] = useState<OnboardingSteps | null>(null);
  const onboardingPath = 'FINANCES';

  const hasCompletedOnboarding = useRef(false);

  useEffect(() => {
    findOnboardingSteps('FINANCES').then((res) => setOnboarding(res));
  }, []);

  const completeCurrentOnboarding = useCallback(() => {
    if (hasCompletedOnboarding.current) {
      return;
    }

    completeOnboarding(onboarding?.id);
    setOnboarding(null);
    hasCompletedOnboarding.current = true;
  }, [onboarding?.id]);

  return (
    <>
      <TitleAndDescription pageName="Your invoices" />

      {!isMobile && onboarding ? (
        <Steps
          enabled={true}
          steps={formatSteps(onboardingPath, onboarding.steps)}
          options={{
            tooltipClass: 'customTooltip',
            showBullets: true,
            doneLabel: GOT_IT,
            nextLabel: NEXT,
            prevLabel: BACK,
          }}
          initialStep={0}
          onExit={completeCurrentOnboarding}
        />
      ) : null}

      {RUSSIAN_LOCALE ? (
        <div className={classes.wrapper}>
          <div className={clsx(classes.title, classes.title_ru)}>
            {t('Your invoices')}
          </div>

          <Grid direction="row">
            <GridItem col={{ default: 12, xsup: 3 }} className={classes.left}>
              <ul className={classes.balance}>
                <li className={classes['balance-count']}>
                  <span className={classes['balance-title']}>
                    {t('Balance')}
                  </span>
                  <h3 className={classes['balance-value']}>
                    {formatBalanceForLocale(
                      balanceValue,
                      currency,
                      currentCurrencySymbol,
                      true
                    )}
                  </h3>
                </li>

                <li>
                  <BonusCount
                    count={balanceBonus}
                    type="default"
                    size="s"
                    isTooltip={!isMediaTablet}
                  >
                    {t('Use bonus points to pay bills')}
                  </BonusCount>
                </li>
              </ul>

              {isStaff && (
                <Button
                  theme="primary"
                  size="big"
                  className={clsx(classes['top-up-balance'])}
                  onClick={handleShowTopUpModal}
                >
                  <span>{t('Top up')}</span>
                </Button>
              )}

              {isMediaTablet && isStaff && (
                <Button
                  theme="primary"
                  size="big"
                  className={clsx(
                    classes['top-up-balance-scroll'],
                    scrollPosition > 320 && classes.scrollBtn
                  )}
                  onClick={handleShowTopUpModal}
                >
                  <span>{t('Top up')}</span>
                </Button>
              )}

              <ModalMobile
                title=""
                isOpen={showTopUpModal}
                onClose={handleCloseTopUpModal}
                isBasicHeader={false}
              >
                <FormTopUpBalance
                  handleTopUpBalance={handleTopUpBalance}
                  value={value}
                  setValue={setValue}
                  showTopUpModal={showTopUpModal}
                />
              </ModalMobile>
            </GridItem>

            <GridItem col={{ default: 12, xsup: 9 }} className={classes.right}>
              <div className={classes.tabs__wrapper}>
                <div className={classes.tabs}>
                  {tabs.map((tab) => (
                    <label key={tab.id} className={classes.tab}>
                      <input
                        type="radio"
                        name="project_tab"
                        id="stages"
                        onChange={() => {
                          setActivTab(tab.id);
                        }}
                        checked={activeTab === tab.id}
                      />
                      <div className={classes.tab_label}>{tab.label}</div>
                    </label>
                  ))}
                </div>

                {/* Might be needed in the future */}
                {/* {activeTab === 2 && (
                  <Button
                    theme={"primary"}
                    size="middle"
                    className={classes.desktop__btn}
                    onClick={handleShowPaymentBillModal}
                  >
                    {t("Pay all bills")}
                  </Button>
                )} */}

                <ModalMobile
                  title=""
                  isOpen={showPaymentBillModal}
                  onClose={handleClosePaymentBillModal}
                  isBasicHeader={false}
                >
                  <FormPaymentBill
                    onClose={handleClosePaymentBillModal}
                    handleShowTopUpModal={handleShowTopUpModal}
                    showPaymentBillModal={showPaymentBillModal}
                  />
                </ModalMobile>
              </div>
              {activeTab === 1 && (
                <HistoryOperation handleShowModal={handleShowTopUpModal} />
              )}
              {activeTab === 2 && (
                <Invoices
                  internalInvoices={internalInvoices}
                  columns={columns}
                  tableActive={tableActive}
                  customPageCount={customPageCount}
                  getDataByPage={getDataByPage}
                  defaultPageSize={itemsPerPage}
                  filter={filter}
                  handleShowPaymentBillModal={handleShowPaymentBillModal}
                />
              )}
              {activeTab === 3 && <Acts />}
            </GridItem>
          </Grid>
        </div>
      ) : (
        <div
          className={clsx(
            classes.wrapper,
            !internalInvoices?.length && 'empty-background'
          )}
        >
          <div style={{ position: 'relative', zIndex: 1 }}>
            <div className={classes.title}>
              {t('Your invoices')}
              <span>{allInvoices}</span>
            </div>

            <Filters
              all={allInvoices}
              paid={paidInvoices}
              topay={toPayInvoices}
              cancelled={cancelledInvoices}
              filter={filter}
              setFilter={getDataByFilter}
            />
          </div>

          {!internalInvoices?.length && (
            <EmptyItems
              isBackground={false}
              title={'You have no invoices'}
              description={'To start a Project please select a Solution'}
            />
          )}

          {internalInvoices?.length > 0 && (
            <div className={classes.main}>
              <Table
                data={tableData(internalInvoices, t)}
                columns={columns}
                tableActive={tableActive}
                customPageCount={customPageCount}
                getDataByPage={getDataByPage}
                defaultPageSize={itemsPerPage}
                mobileModeTitle={t('New ones first')}
                filter={filter}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
});
