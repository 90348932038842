export const FIRST_EVENT_TEXT =
  "<p style='margin-bottom: 16px;'><strong>На вебинаре обсудим создание личного бренда с СЕО Pi-Shaped.</strong></p><p style='margin-bottom: 16px;'>«Личный бренд» стал очередным мемом: популярные соцсети завалены историями про «успешный успех» часто довольно странных персонажей.</p><p style='margin-bottom: 16px;'>Нормального человека-предпринимателя, эксперта, менеджера с одной стороны мучает страх пропустить что-то важное, т.н. fear of missing out, а с другой стороны чувство брезгливости к таким странным персонажам и их подходам.</p><p style='margin-bottom: 16px;'>Но есть личный бренд «здорового человека», и о нём мы хотим поговорить на нашем вебинаре. СЕО бизнес-юнита Pi-Shaped Группы Компаний Kokoc Group поговорит с участниками на следующие темы:</p><ol style='padding-left: 30px;'><li style='margin-bottom: 4px;'>ROI личного бренда — зачем мне тратить свое время и ресурсы? И сколько ресурсов понадобится? Подходы к измерению эффективности инвестиций в личный бренд</li><li style='margin-bottom: 4px;'>Как не потерять (а лучше найти) себя, занимаясь личным брендом. Как ваша индивидуальность может стать ключом к успеху</li><li style='margin-bottom: 4px;'>Экспертность — как подчеркнуть свои «суперсилы»?</li></ol>";

export const SECOND_EVENT_TEXT =
  "<p style='margin-bottom: 16px;'><strong>Обсудим основные принципы, которые могут быть полезны компаниям и брендам, работающим в категориях с высоким риском возврата глобальных игроков.</strong></p><p style='margin-bottom: 16px;'>Группа онлайн и офлайн экспертов обсудит сценарии, когда компании возвращаются на рынок после перерыва в маркетинговой и коммуникационной активности (в силу влияния финансовых проблем, ребрендинга, геополитических факторов и пр. на бизнес).</p><p style='margin-bottom: 16px;'>Будут рассмотрены различные сценарии, барьеры и даны рекомендации в разрезе ключевых отраслей бизнеса.</p>";

export const THIRD_EVENT_TEXT =
  "<p style='margin-bottom: 16px;'><strong>Роль стратегии бренда и «эффекта premium» в бизнес-результатах компании.</strong></p><p style='margin-bottom: 16px;'>Основанные на данных принципы и практические рекомендации для средних и растущих бизнесов по созданию дополнительной ценности и росту маржинальности через построение бренда и повышение его премиальности.</p>";

export const FOURTH_EVENT_TEXT =
  "<p style='margin-bottom: 16px;'><strong>Блогеры как ключ к успеху бренда: тренды, форматы и секреты эффективного сотрудничества за 1 час.</strong></p>" +
  "<p style='margin-bottom: 16px;'>Инфлюенс-маркетинг стал одной из значимых стратегий продвижения в условиях современных реалий рынка. 85% россиян активно следят за блогерами, а 53% аудитории до 35 лет доверяет блогерам, поэтому бренды могут использовать это доверие для привлечения клиентов. Но как оценить качество аудитории и эффективность размещений? Где искать инфлюенсоров с живой аудиторией? Как делать нативные интеграции когда действует закон о маркировке? Обо всем этом поговорим на вебинаре Программы Роста.</p>" +
  "<p><strong>Для кого вебинар:</strong></p>" +
  "<ul style='margin-bottom: 16px; list-style-type: disc; padding-left: 20px;'>" +
    "<li>маркетологи</li>" +
    "<li>директора по маркетингу</li>" +
    "<li>предприниматели</li>" +
    "<li>все, кто заинтересован в повышении эффективности своих рекламных кампаний</li>" +
  "</ul>" +
  "<p><strong>Зачем участвовать:</strong></p>" +
  "<ul style='margin-bottom: 16px; list-style-type: disc; padding-left: 20px;'>" +
    "<li>Поймете основные тренды в инфлюенс-маркетинге</li>" +
    "<li>Получите практические советы по взаимодействию с блогерами</li>" +
    "<li>Разберетесь в специфике и особенностях различных форматов и платформ</li>" +
    "<li>Узнаете, чем отличаются макро-, мега- и микроинфлюенсеры, и на какие задачи их подключать</li>" +
  "</ul>" +
  "<p><strong>Спикер:</strong></p>" +
  "<p style='margin-bottom: 16px;'>Василий Ящук, CEO Players Team</p>" +
  "<p>Не упустите шанс узнать, как инфлюенс-маркетинг может изменить ваш бизнес!</p>";

export const FIFTH_EVENT_TEXT =
"<p style='margin-bottom: 16px;'><strong>Вебинар об актуальных трендах Retail Media и стратегиях повышения эффективности рекламных кампаний.</strong></p>" +
"<p style='margin-bottom: 16px;'>Ежегодное увеличение бюджетов на Retail Media на 10%–30% даже в условиях инфляции подчеркивает, как важно для бизнеса адаптироваться к современным условиям.</p>" + 
"<p style='margin-bottom: 16px;'>На вебинаре Александра Новичкова, CEO Российского направления ZoneSmart (Kokoc Group), поделится результатами свежего исследования, которое выявляет ключевые тренды и изменения в сфере Retail Media. Александра проанализирует текущие мегатренды и предложит практические рекомендации по их использованию для повышения конкурентоспособности бизнеса.</p>"+
"<p>Вы получите ответы на такие вопросы:</p>" +
"<ul style='margin-bottom: 16px; list-style-type: disc; padding-left: 20px;'>" +
    "<li>Какие метрики использовать при оценке эффективности рекламных кампаний в Retail Media?</li>" +
    "<li>Как интегрировать Retail Media в текущие стратегические планы и маркетинговые бюджеты?</li>" +
    "<li>Какие тактики и стратегии выбрать при оптимизации рекламы в Retail Media?</li>" +
    "<li>Каковы ожидания маркетологов от retail-платформ в условиях повышенной конкуренции?</li>" +
  "</ul>" +
  "<p style='margin-bottom: 16px;'>Мероприятие будет полезно директорам по маркетингу, маркетологам и владельцам бизнеса, желающим оптимизировать свои рекламные операции и повысить их эффективность. Вы также сможете задать вопросы и получить ответы в ходе обсуждения, что сделает ваше участие ещё более ценным.</p>" +
  "<p style='margin-bottom: 16px;'>Не упустите возможность узнать о ключевых трендах Retail Media и улучшить свои бизнес-процессы.</p>";


export const SIXTH_EVENT_TEXT = 
"<p style='margin-bottom: 16px;'><strong>Узнайте, как правильно выстраивать коммуникации с клиентами для роста конверсии и увеличения LTV.</strong></p>" +
"<p style='margin-bottom: 16px;'>Более 94% клиентов покидают сайты без покупок. Это подчеркивает важность использования всех доступных инструментов для захвата и удержания аудитории. Грамотно выстроенная стратегия коммуникаций может привести к инкрементальному эффекту до 15% и росту доли выручки от CRM-маркетинга до 25%.</p>" + 
"<p style='margin-bottom: 16px;'>На новом вебинаре Программы Роста Дарья Швалова, руководитель группы продаж Kokoc Group расскажет о том, как правильно выстраивать коммуникации для повышения конверсии и увеличения LTV клиентов.</p>"+
"<p>На мероприятии разберем:</p>"+
"<ul style='margin-bottom: 16px; list-style-type: disc; padding-left: 20px;'>" +
    "<li>Как CRM-маркетинг помогает увеличить выручку бизнеса на 20%</li>" +
    "<li>Основные каналы взаимодействия: email, социальные сети и мессенджеры, и их эффективное использование.</li>" +
    "<li>Как измерять успех ваших стратегий: ключевые метрики и показатели.</li>" +
    "<li>Персонализация как ключевой фактор роста: как внедрить индивидуальный подход к клиентам.</li>" +
    "<li>Как понять на каком уровне развития находится CRM-маркетинг и куда дальше развивать канал.</li>" +
    "<li>Практические примеры успешных кейсов из различных отраслей.</li>" +
  "</ul>"+
  "<p style='margin-bottom: 16px;'>Ждем на вебинаре маркетологов, директоров по маркетингу и владельцев бизнеса. Вы получите полезные идеи и практические инструменты.</p>";