import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ProductCard } from '../CardsContainer/ProductList/ProductCard';
import classes from './ShowcaseContainer.module.scss';
import Footer from 'components/common/Footer';
import { useAuthStore } from 'contexts/AuthContext';
import { useMarketPlaceStore } from 'contexts/MarketPlaceContext';
import { useTranslation } from 'react-i18next';
import { getLangName } from 'tools/utils';
import clsx from 'clsx';
import { Pagination } from 'components/shared/Pagination';
import { SolutionType } from 'types/solutions';
import { queryParamsHandler } from '../CardsContainer/utils';
import { ShowcaseHeader } from './ShowcaseHeader';
import { useUserStore } from 'contexts/UserContext';
import ProductListSkeleton from 'components/shared/Skeleton/ProductListSkeleton/ProductListSkeleton';

export const ShowcaseContainer = observer(() => {
  const { isPermissionError, isLocaleError } = useUserStore() || {};
  const { loadShowcase, showCase, isLoadingServicePages } =
    useMarketPlaceStore() || {};
  const { isServerError } = useAuthStore() || {};
  const showFooter = !isServerError && !isPermissionError && !isLocaleError;
  const { t, i18n } = useTranslation();

  const [services, setServices] = useState<SolutionType[]>(
    showCase?.services?.results
  );

  const [showText, setShowText] = useState(!showCase?.short_description);
  const [currentPage, setCurrentPage] = useState(1);
  const [chunks, setChunks] = useState<SolutionType[][]>([]);
  const chunkSize = 12;
  const count = showCase?.services?.count;

  const showCaseData = {
    title: getLangName(showCase, 'name', i18n),
    date_diff: showCase?.date_diff,
    image_desktop: showCase?.image_desktop,
    image_mobile: showCase?.image_mobile,
    header_background: showCase?.header_background,
    card_background: showCase?.card_background,
    description: getLangName(showCase, 'description', i18n),
    short_description: getLangName(showCase, 'short_description', i18n),
  };

  const changeChunks = () => {
    let newArray: SolutionType[][] = [];
    for (let i = 0; i < count; i += chunkSize) {
      const chunk = services?.slice(i, i + chunkSize);
      newArray = [...newArray, chunk];
    }
    return newArray;
  };

  const params = queryParamsHandler({
    page: currentPage,
    page_size: chunkSize,
  });

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (showCase?.slug) {
      loadShowcase(showCase?.slug, params).then((result) => {
        setServices(result?.services?.results);
      });
    }
  }, [params]);

  useEffect(() => {
    setChunks(changeChunks());
  }, []);

  return (
    <div className={classes.container}>
      <ShowcaseHeader
        data={showCaseData}
        showText={showText}
        setShowText={setShowText}
        t={t}
      />
      <div className={clsx('layout-container', classes.container__mainContent)}>
        <div className={classes.container__mainContent_block}>
          {isLoadingServicePages ? (
            <ProductListSkeleton isShowcase />
          ) : (
            <div className={classes.list}>
              {services?.map((item: SolutionType, index: number) => (
                <ProductCard
                  key={`${item.id}-${index}`}
                  data={item}
                  sourceType="showcase"
                  cardBackground={showCaseData.card_background}
                  isShowcase
                />
              ))}
            </div>
          )}

          {chunks?.length > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={chunks.length}
              setPageNumber={setCurrentPage}
            />
          )}
        </div>
      </div>
      {showFooter && <Footer />}
    </div>
  );
});
