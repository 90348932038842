import ColorSticker, { StickerColor } from 'components/shared/ColorSticker';
import classes from './ResearchCard.module.scss';
import back_image_1 from 'assets/img/GrowthProgram/bg-card-1.png';
import back_image_2 from 'assets/img/GrowthProgram/bg-card-2.png';
import back_image_3 from 'assets/img/GrowthProgram/bg-card-3.png';
import FileLink from 'components/shared/FileLink';
import React, { useLayoutEffect, useRef } from 'react';
import clamp from 'clamp-js';
import clsx from 'clsx';
import { pushSimpleEventToDataLayer, pushToDataLayer } from 'tools/analytics';

interface ResearchCardProps {
  section: string;
  title: string;
  description: string;
  fileLink: string;
  fileSize: number;
  fileDate: string;
  tagColor: StickerColor;
  currentIndex: number;
}

const titleMapping: Record<string, string> = {
  'Коды премиальности на Wildberries и Ozon в категории одежды': 'PremiumCodes',
  'Reels-стратегия на трендах (лето 2024)': 'ReelsStrategy',
  'Онлайн-аптеки в России в 2024 г., обзор-прогноз': 'OnlinePharmacies',
};

const backgrounds = [
  { image: back_image_1, className: classes.background },
  { image: back_image_2, className: clsx(classes.background, classes.bottom) },
  { image: back_image_3, className: clsx(classes.background, classes.left) },
];

const ResearchCard = (props: ResearchCardProps) => {
  const textRef = useRef<HTMLParagraphElement | null>(null);

  useLayoutEffect(() => {
    if (textRef.current) {
      clamp(textRef.current, { clamp: 6 });
    }
  }, []);


  const selectBackground = (index: number) => {
    const { image, className } = backgrounds[index % backgrounds.length];
    return <img src={image} alt="" className={className} />;
  };

  const handleDownloadClick = (fileName: string) => {
    const titleNameMap = titleMapping[props.title] || 'UnknownTitle';
    const eventName = `clickLinkDownloadResearch${titleNameMap}`;
    pushSimpleEventToDataLayer(eventName, fileName);
  };
  

  return (
    <div className={classes.card}>
      {selectBackground(props.currentIndex)}
      <ColorSticker
        color={props.tagColor}
        text={props.section}
        className={classes.card__sticker}
      />
      <span className={classes.card__title}>{props.title}</span>
      <p ref={textRef} className={classes.card__description}>
        {props.description}
      </p>
      <FileLink
        size={props.fileSize}
        date={props.fileDate}
        link={props.fileLink}
        onDownload={handleDownloadClick}
        linkName="Скачать PDF"
        className={classes.card__link}
      />
    </div>
  );
};

export default ResearchCard;
