import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { pushOneEventToDataLayer, sendToYandexMetrika } from 'tools/analytics';
import { Request } from 'tools/request';
import * as yup from 'yup';

export const useFormRequest = (onClose: () => void, isFavorites?: boolean) => {
  const { t, i18n } = useTranslation();
  const validationSchema = yup.object().shape({
    solution: yup.string().required(t('This field is required')),
    source: yup.string().required(t('This field is required')),
  });
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    reset,
  } = useForm({ resolver: yupResolver(validationSchema), mode: 'all' });

  const [disabled, setDisabled] = useState(true);
  const [serviceValue, setServiceValue] = useState('');
  const [sourceValue, setSourceValue] = useState('');
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    project_description: '',
    service_name: '',
  });

  useEffect(() => {
    if (
      serviceValue !== '' &&
      sourceValue !== '' &&
      !!serviceValue.trim() &&
      !!sourceValue.trim()
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [serviceValue, sourceValue]);

  const onCloseSuccess = () => {
    setSuccess(false);
    onClose();
  };

  const sendRequest = useCallback(async () => {
    const formData = new FormData();
    const sourcePage = window.location?.href;
    formData.append('service_name', serviceValue);
    formData.append('project_description', sourceValue);
    formData.append('source_page', sourcePage);

    if (disabled) {
      return;
    }

    setDisabled(true);

    try {
      await Request({
        type: 'POST',
        url: `${process.env.REACT_APP_API}/api/v1/services/service_request/`,
        data: formData,
      });
      setSuccess(true);
      const goal = isFavorites
        ? 'sendFormSuccessOfferSolutionFavorites'
        : 'sendFormSuccessOfferSolutionCatalog';

      pushOneEventToDataLayer(goal);
      sendToYandexMetrika(goal);
      
      setDisabled(false);
      setServiceValue('');
      setSourceValue('');
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error.response) {
          const { data } = error.response;
          if (data) {
            setErrorMessage(data);
          }
        }
        setDisabled(false);
      }
    }
  }, [serviceValue, sourceValue, disabled]);

  const submit = useCallback(() => {
    void sendRequest();
    reset();
  }, [reset, sendRequest]);

  return {
    success,
    handleSubmit,
    submit,
    t,
    control,
    errorMessage,
    setServiceValue,
    setSourceValue,
    i18n,
    isValid,
    onCloseSuccess,
  };
};
