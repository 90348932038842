import { Button } from 'components/shared/Button';
import { Input } from 'components/shared/Input';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { Type } from 'components/shared/Type';
import { Controller } from 'react-hook-form';
import { getLinkForPrivacy } from '../getLinkForPrivacy';
import classes from './Personal.module.scss';
import { PersonalProps } from './Personal.props';
import { helperTextForRole, usePersonal } from './usePersonal';
import { RUSSIAN_LOCALE } from 'utils';
import { PhoneInputMaskFull } from 'components/shared/PhoneInputFull';
import { AttentionIcon2 } from 'components/shared/Icons/Attention2';
import { getLangName } from 'tools/utils';
import { CLIENT_ROLE } from 'types/user';

export const Personal = ({
  defaultValue,
  getParams,
  client,
}: PersonalProps) => {
  const {
    t,
    i18n,
    formRef,
    handleSubmit,
    onSubmit,
    control,
    errors,
    setFullName,
    email,
    disableSubmit,
    setPhone,
  } = usePersonal(defaultValue, getParams);

  return (
    <>
      <TitleAndDescription pageName="Account/personal" />

      <div className={classes.wrapper}>
        <div className={classes.titleWrap}>
          <Type tag={'h2'} className={classes.title}>
            {t('Personal information')}
          </Type>
        </div>
        <p className={classes['info']}>
          {t('Viewing profile information. By providing contact information,')}
          <br />
          {t('you agree to the terms of the')}{' '}
          <a
            className={classes['info__link']}
            href={getLinkForPrivacy(i18n.language)}
            target="_blank"
            rel="noreferrer"
          >
            {t('Privacy Policy (personal)')}
          </a>
        </p>

        {RUSSIAN_LOCALE && (
          <div className={classes.attention}>
            <AttentionIcon2 style={{ flexShrink: 0 }} />

            <ul className={classes.attention__list} translate="no">
              <li className={classes.attention__title}>
                {t('Data obtained from Kokoc ID')}
              </li>
              <li className={classes.attention__description}>
                {t('To update the information, go to your')}{' '}
                <a
                  href={`${process.env.REACT_APP_KID_URL}dashboard/passport`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.attention__link}
                  title={`${process.env.REACT_APP_KID_URL}dashboard/passport`}
                >
                  {t('Kokoc ID profile')}
                </a>
                .{' '}
                {t(
                  'The data remains confidential, and updating it in the profile ensures reliability'
                )}
              </li>
            </ul>
          </div>
        )}

        <form
          className={classes.inner}
          ref={formRef}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={classes.box}>
            <div className={classes.groupRow}>
              <div className={classes.groupField}>
                <Controller
                  name="fullName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      bigInput
                      inputLabel={true}
                      className={classes.input}
                      onChange={(e) => {
                        field.onChange(e);
                        setFullName(e);
                      }}
                      type={'text'}
                      placeholder={t('Full name')}
                      value={field.value}
                      disabled={RUSSIAN_LOCALE}
                      isBackground
                      error={errors.fullName ? t('Invalid name') : undefined}
                      maxLength={50}
                    />
                  )}
                />
              </div>

              <div className={classes.groupField}>
                <Controller
                  name="role"
                  control={control}
                  render={() => (
                    <Input
                      bigInput
                      inputLabel={true}
                      className={classes.input}
                      disabled
                      type={'text'}
                      placeholder={t('Role on platform')}
                      value={
                        client
                          ? getLangName(client?.permission, 'name', i18n)
                          : t('VIEWER')
                      }
                      isBackground
                      helperText={helperTextForRole(
                        client
                          ? client?.permission?.type
                          : ('VIEWER' as CLIENT_ROLE)
                      )}
                      wadeTooltip
                    />
                  )}
                />
              </div>
            </div>

            <div className={classes.groupRow}>
              <div className={classes.groupField}>
                <Controller
                  name="email"
                  control={control}
                  render={() => (
                    <Input
                      bigInput
                      inputLabel={true}
                      className={classes.input}
                      disabled
                      type={'text'}
                      placeholder={t('Work email')}
                      value={email}
                      isBackground
                    />
                  )}
                />
              </div>
              <div className={classes.groupField}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <PhoneInputMaskFull
                      bigInput
                      inputLabel={true}
                      className={classes.input}
                      onChange={(e) => {
                        setPhone(e);
                        field.onChange(e);
                      }}
                      type={'text'}
                      placeholder={t('Phone number')}
                      value={field.value}
                      disabled={RUSSIAN_LOCALE}
                      isBackground
                      error={
                        errors.phone ? t('Invalid phone number') : undefined
                      }
                    />
                  )}
                />
              </div>
            </div>
          </div>

          {!RUSSIAN_LOCALE && (
            <div className={classes.submit}>
              <Button
                className={classes.button}
                theme="primary"
                size="middle"
                disabled={disableSubmit}
              >
                {t('Save changes')}
              </Button>
            </div>
          )}
        </form>
      </div>
    </>
  );
};
