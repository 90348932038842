import clsx from 'clsx';
import { Logo } from 'components/common/Logo';
import { Button } from 'components/shared/Button';
import { CrossIcon } from 'components/shared/Icons/CrossIcon';
import { MenuGradientIcon } from 'components/shared/Icons/MenuGradientIcon';
import { SearchGradientIcon } from 'components/shared/Icons/SearchGradientIcon';
import { Modal } from 'components/shared/Modal';
import { languages } from 'constants/languages';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classes from './Header.module.scss';
import { HeaderProps } from './Header.props';
import { LocalPreferencesForm } from './LocalPreferences';
import HeaderButtons from './HeaderButtons/HeaderButtons';
import { useHeader } from './useHeader';
import HeaderNav from './HeaderNav/HeaderNav';
import { authStore } from 'stores/authStore';

export const Header = observer(
  ({ disabled = false, allowLogout }: HeaderProps) => {
    const {
      active,
      isDisableHeader,
      setOpenSearch,
      openSearch,
      isConfirmedEmail,
      openMenu,
      closeMenu,
      modalListItems,
      handleOpenLocalPreferencesModal,
      path,
      userName,
      isSuccessLocalPreferences,
      isActiveLink,
      isManager,
      isAdmin,
      goToKokocID,
      goToGrowthProgram,
      resetCookies,
      isTestUser,
      handleSendLocalPreferences,
      handleCloseLocalPreferencesModal,
      isOpenLocalPreferencesModal,
      setIsOpenLocalPreferencesModal,
      userCurrency,
      userCountry,
      currencies,
      countries,
      bottomMenuSize,
      isShowHeaderButtons,
      isDisabledLocalPreferences,
      isShowHeaderAdminList,
      userClient,
      filteredLanguages,
    } = useHeader();
    const { t, i18n } = useTranslation();

    return (
      <>
        <header
          className={clsx(classes['header'], active && classes['active'])}
        >
          <div
            className={clsx(
              classes['header__inner'],
              authStore.isServerError && classes.disabled
            )}
          >
            <Logo />

            <div className={classes['header__activation']}>
              <div
                className={classes['header__search-icon']}
                onClick={() => !isDisableHeader && setOpenSearch(!openSearch)}
              >
                <SearchGradientIcon />
              </div>
              <div
                className={clsx(
                  classes['header__burger'],
                  isDisableHeader && isConfirmedEmail && classes.disabled
                )}
              >
                {!active ? (
                  <Button
                    className={clsx(
                      disabled && !allowLogout && classes.disabled
                    )}
                    onClick={openMenu}
                  >
                    <MenuGradientIcon />
                  </Button>
                ) : (
                  <Button onClick={closeMenu}>
                    <CrossIcon
                      width={'15px'}
                      height={'15px'}
                      fill={'#252529'}
                    />
                  </Button>
                )}
              </div>
            </div>
          </div>

          <HeaderNav
            openSearch={openSearch}
            isDisableHeader={isDisableHeader}
            setOpenSearch={setOpenSearch}
            handleOpenLocalPreferencesModal={handleOpenLocalPreferencesModal}
            modalListItems={modalListItems}
            allowLogout={allowLogout}
            isConfirmedEmail={isConfirmedEmail}
            disabled={disabled}
            isAdmin={isAdmin}
            isManager={isManager}
            isSuccessLocalPreferences={isSuccessLocalPreferences}
            isActiveLink={isActiveLink}
            path={path}
            userName={userName}
            goToKokocID={goToKokocID}
            goToGrowthProgram={goToGrowthProgram}
            resetCookies={resetCookies}
            isTestUser={isTestUser}
            active={active}
            closeMenu={closeMenu}
            isShowHeaderAdminList={isShowHeaderAdminList}
            setIsOpenLocalPreferencesModal={setIsOpenLocalPreferencesModal}
            userClient={userClient}
          />

          <Modal
            isOpen={isOpenLocalPreferencesModal}
            title={t('Local preferences')}
            onClose={handleCloseLocalPreferencesModal}
          >
            <LocalPreferencesForm
              onClose={handleCloseLocalPreferencesModal}
              onSave={handleSendLocalPreferences}
              disabled={isDisabledLocalPreferences}
              noticeText={t(
                'Local settings affect the interface language and prices you see on the platform'
              )}
              countries={countries}
              currencies={currencies}
              languages={filteredLanguages}
              currentCountry={userCountry}
              currentCurrency={userCurrency}
              currentLanguage={
                languages.find((item) => item.lang_code === i18n.language)!
              }
            />
          </Modal>
        </header>

        {isShowHeaderButtons && (
          <HeaderButtons
            userName={userName}
            isSuccessLocalPreferences={isSuccessLocalPreferences}
            isActiveLink={isActiveLink}
            path={path}
            allowLogout={allowLogout}
            isDisableHeader={isDisableHeader}
            bottomMenuSize={bottomMenuSize}
          />
        )}
      </>
    );
  }
);
