import React, { FC, useMemo } from 'react';
import classes from '../Cases.module.scss';
import { getLangName } from 'tools/utils';
import { AMOUNT_TYPE } from 'constants/enums';
import { formatCasesForLocale } from '../formattedCases';
import { SingleCaseProps } from './SingleCase.props';
import { observer } from 'mobx-react-lite';
import { InternalCaseKPI, Platform } from 'types/solutions';
import { toJS } from 'mobx';
import { useWindowWidth } from 'hooks/useWindowWidth';
import clsx from 'clsx';

const SingleCase: FC<SingleCaseProps> = observer(({ singleCase, i18n }) => {
  const internalCase = useMemo(() => toJS(singleCase), [singleCase]);

  const businessType = getLangName(singleCase?.business_type, 'name', i18n);
  const { width } = useWindowWidth();

  const isLargeLaptop = width > 1024 && width < 1360;

  return (
    <div className={classes.case}>
      <div className={classes['image-wrapper']}>
        <img className={classes.image} src={internalCase?.image} alt="" />
      </div>

      <div className={classes.info}>
        <div>
          <div className={classes.head}>
            <div className={classes.type}>{businessType}</div>

            <div className={classes.tags}>
              {internalCase?.platform?.slice(0, 3)?.map((tag: Platform) => {
                if (tag.image) {
                  return (
                    <div className={classes.tag_img} key={tag.id}>
                      <img
                        className={classes.platform}
                        src={tag.image}
                        alt=""
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>

          <div className={classes.body}>
            <div className={classes.name}>
              {getLangName(internalCase, 'name', i18n)}
            </div>
          </div>
        </div>

        <div className={classes.kpises}>
          {internalCase?.case_kpises?.map(
            (kpi: InternalCaseKPI, index: number) => (
              <div className={classes.kpi} key={kpi?.name + index}>
                <div className={classes.description}>{kpi?.description}</div>

                <div className={classes.amount}>
                  {
                    AMOUNT_TYPE[
                      kpi.amount_type as unknown as keyof typeof AMOUNT_TYPE
                    ]
                  }
                  {formatCasesForLocale(kpi.amount, kpi.amount_unit, 2)}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
});

export default SingleCase;
