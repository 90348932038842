import MainBlock from './components/MainBlock';
import classes from './EventDetail.module.scss';
import BreadCrumbs from 'components/shared/BreadCrumbs';
import {
  FIRST_EVENT_TEXT,
  FOURTH_EVENT_TEXT,
  SECOND_EVENT_TEXT,
  THIRD_EVENT_TEXT,
  FIFTH_EVENT_TEXT,
  SIXTH_EVENT_TEXT
} from './tempText';
import return_past_img from 'assets/img/GrowthProgramPastNew/return_past_new.jpg';
import expert_past_img from 'assets/img/GrowthProgramPastNew/expert_past_new.jpg';
import secret_past_img from 'assets/img/GrowthProgramPastNew/secret_past_new.jpg';
import maximum_img from 'assets/img/GrowthProgram/maximum.png'
import influence_past_img from 'assets/img/GrowthProgramPastNew/influence_past_new.png'
import retail_img from 'assets/img/GrowthProgram/retail.png'
import { useParams } from 'react-router-dom';
import AnnounceCard from './components/AnnounceCard';
import { StickerColor } from 'components/shared/ColorSticker';
import { useWindowWidth } from 'hooks/useWindowWidth';
import React, { useEffect, useRef, useState } from 'react';
import { useIsVisible } from 'hooks/useIsVisible';
import { Modal } from 'components/shared/Modal';
import { ModalMobile } from 'components/shared/ModalMobile';
import Map from './components/Map';
import { useGrowthProgramStore } from 'contexts/GrowthProgram';
import { PageLoader } from 'components/shared/PageLoader';
import { observer } from 'mobx-react-lite';

const mockEvents: {
  id: number;
  slug: string;
  title: string;
  description: string;
  tags: { color: StickerColor; text: string }[];
  date: string;
  img?: string;
  link?: string;
  location?: {
    name: string;
    coords: number[];
  };
  downloadFile?: string;
}[] = [
  {
    id: 1,
    img: secret_past_img,
    slug: 'sekrety-premium-kak-prodavatsya-dorozhe',
    title: 'Секреты premium - как продаваться дороже?',
    description: THIRD_EVENT_TEXT,
    tags: [
      { color: 'green' as StickerColor, text: 'Онлайн' },
      { color: 'purple' as StickerColor, text: 'Средний бизнес' },
      {
        color: 'yellow' as StickerColor,
        text: 'Растущий бизнес',
      },
    ],
    date: '03.03.2025 в 10:30',
    link: 'https://pruffme.com/landing/u2705426/tmp1737021528',
    downloadFile: '/files/GrowthProgram/Секреты_premium_как_продавать_дороже.pdf',
  },
  {
    id: 2,
    img: expert_past_img,
    slug: 'ekspertnost-kak-podcherknut-svoi-supersily',
    title: 'Экспертность — как подчеркнуть свои «суперсилы»?',
    description: FIRST_EVENT_TEXT,
    tags: [
      { color: 'green' as StickerColor, text: 'Онлайн' },
      { color: 'purple' as StickerColor, text: 'Личный бренд' },
    ],
    date: '19.02.2025 в 12:00',
    link: 'https://pruffme.com/landing/u2705426/tmp1737021425',
    downloadFile: '/files/GrowthProgram/Экспертность_— как_подчеркнуть_свои_суперсилы.pdf',
  },
  {
    id: 3,
    img: return_past_img,
    slug: 'inogda-oni-vozvrashchayutsya-a-chto-esli',
    title: 'Иногда они возвращаются: а что, если?..',
    description: SECOND_EVENT_TEXT,
    tags: [
      { color: 'green' as StickerColor, text: 'Онлайн' },
      { color: 'yellow' as StickerColor, text: 'Мультикатегорийная' },
    ],
    date: '07.02.2025 в 11:00',
    link: 'https://pruffme.com/landing/u2705426/tmp1737021109',
    downloadFile: '/files/GrowthProgram/Иногда они возвращаются.pdf',
  },
  {
    id: 4,
    img: influence_past_img,
    slug: 'inflyuens-marketing-2025-effektivnye-strategii',
    title: 'Инфлюенс-маркетинг 2025: эффективные стратегии',
    description: FOURTH_EVENT_TEXT,
    tags: [
      { color: 'green' as StickerColor, text: 'Онлайн' },
      { color: 'purple' as StickerColor, text: 'Инфлюенс-маркетинг' },
    ],
    date: '26.03.2025 в 11:00',
    link: 'https://pruffme.com/landing/u2705426/tmp1741261285',
    downloadFile: '/files/GrowthProgram/Василий_Ящук_— Инфлюенс_маркетинг.pdf'
  },
  {
    id: 5,
    img: retail_img,
    slug: 'retail-media-kak-raskryt-potentsial-dlya-brenda',
    title: 'Retail Media — как раскрыть потенциал для бренда?',
    description: FIFTH_EVENT_TEXT,
    tags: [
      { color: 'green' as StickerColor, text: 'Онлайн' },
      { color: 'yellow' as StickerColor, text: 'Тренды' },
    ],
    date: '17.04.2025 в 13:00',
    link: 'https://pruffme.com/landing/u2705426/tmp1742890021',
  },
  {
    id: 6,
    img: maximum_img,
    slug: 'vyrychka-na-maksimum-strategiya-raboty-s-bazoy',
    title: 'Выручка на максимум: стратегия работы с базой',
    description: SIXTH_EVENT_TEXT,
    tags: [
      { color: 'green' as StickerColor, text: 'Онлайн' },
      { color: 'purple' as StickerColor, text: 'CRM-маркетинг' },
    ],
    date: '20.05.2025 в 14:00',
    link: 'https://pruffme.com/landing/u2705426/tmp1742916385',
  },
];

const EventDetailContainer = () => {
  const { slug } = useParams();
  const { width } = useWindowWidth();
  const nextEventRef = useRef<HTMLDivElement | null>(null);
  const [showMap, setShowMap] = useState(false);
  const growthStore = useGrowthProgramStore();
  const [activeEventId, setActiveEventId] = useState<number | null>(null);

  const parseEventDate = (dateStr: string) => {
    return new Date(dateStr.split(' в ')[0].split('.').reverse().join('-'));
  };

  const isEventUpcoming = (eventDate: string) => {
    return parseEventDate(eventDate) > new Date();
  };

  const currentEvent = mockEvents.find((event) => event.slug === slug);
  const isCurrentEventUpcoming = currentEvent ? isEventUpcoming(currentEvent.date) : false;

  const upcomingEvents = mockEvents
    .filter(event => isEventUpcoming(event.date))
    .sort((a, b) => parseEventDate(a.date).getTime() - parseEventDate(b.date).getTime());

  const currentUpcomingIndex = upcomingEvents.findIndex(e => e.slug === slug);
  const isUpcomingLast = currentUpcomingIndex === upcomingEvents.length - 1;

  const nextEvent = !isCurrentEventUpcoming
    ? upcomingEvents[0] 
    : upcomingEvents[currentUpcomingIndex + 1]; 

  const shouldShowNextEvent = (!isCurrentEventUpcoming && upcomingEvents.length > 0) ||
    (isCurrentEventUpcoming && !isUpcomingLast);

  const isNextEventVisible = useIsVisible(nextEventRef, undefined, [
    growthStore.isLoadingSubscribeStatus,
  ]);

  const callbacks = {
    onSubmitEvent: () => {
      if (currentEvent) {
        growthStore.subscribeEvent(currentEvent?.id);
      }
    },
  };

  useEffect(() => {
    if (currentEvent?.id && currentEvent.slug === slug) {
      setActiveEventId(currentEvent.id);
    }
  }, [currentEvent?.id, slug]);

  useEffect(() => {
    if (activeEventId !== null) {
      growthStore.getSubscribeStatus(activeEventId);
    }
  }, [activeEventId]);

  return (
    <div key={activeEventId} className={classes.wrapper}>
      <BreadCrumbs
        link="/programma-rosta/"
        linkName="Программа роста"
        className={classes.breadcrumbs}
        items={[currentEvent?.title || '#']}
      />

      {growthStore.isLoadingSubscribeStatus ? (
        <PageLoader />
      ) : (
        <div className={classes.content}>
          {currentEvent && (
            <>
              <MainBlock
                title={currentEvent.title}
                tags={currentEvent.tags}
                imgSrc={currentEvent?.img}
                eventDate={currentEvent.date}
                eventLocation={currentEvent.location?.name}
                eventLink={currentEvent.link}
                buttonName="Буду участвовать"
                description={currentEvent.description}
                showFloatButton={
                  !growthStore.isLoadingSubscribeStatus && !isNextEventVisible
                }
                onShowMap={() => setShowMap(true)}
                onSubscribeEvent={callbacks.onSubmitEvent}
                disabledEventButton={growthStore.currentEventSubscribed}
                isLoadingSubscribe={growthStore.isLoadingSubscribe}
                downloadFile={currentEvent.downloadFile} 
              />
              <div className={classes.announceBlocks}>
                {width > 600 && (
                  <AnnounceCard
                    title={currentEvent.title}
                    eventDate={currentEvent.date}
                    eventLocation={currentEvent.location?.name}
                    eventLink={currentEvent.link}
                    onSubscribeEvent={callbacks.onSubmitEvent}
                    disabledEventButton={growthStore.currentEventSubscribed}
                    isLoadingSubscribe={growthStore.isLoadingSubscribe}
                    downloadFile={currentEvent.downloadFile}
                  />
                )}
                {shouldShowNextEvent && nextEvent && (
                  <AnnounceCard
                    ref={nextEventRef}
                    title={nextEvent.title}
                    eventDate={nextEvent.date}
                    eventLocation={nextEvent.location?.name}
                    eventLink={nextEvent.link}
                    nextLink={`/programma-rosta/${nextEvent.slug}`}
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}

      {currentEvent?.location && width > 640 && (
        <Modal
          isOpen={showMap}
          onClose={() => setShowMap(false)}
          subModal={true}
          title=""
          className={classes.modalFilter}
          classNameForContent={classes.modalMobileFilters}
          withMediaMobile={false}
        >
          <Map
            addressName={currentEvent?.location?.name}
            coordinates={currentEvent.location.coords}
            zoomPosition={{ right: 10, bottom: 30 }}
          />
        </Modal>
      )}

      {currentEvent?.location && width < 640 && (
        <ModalMobile
          title=""
          isOpen={showMap}
          onClose={() => setShowMap(false)}
          isBasicHeader={false}
        >
          <Map
            addressName={currentEvent?.location?.name}
            coordinates={currentEvent.location.coords}
            zoomPosition={{ left: 10, bottom: 40 }}
          />
        </ModalMobile>
      )}
    </div>
  );
};

export default observer(EventDetailContainer);
