import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import ReCAPTCHA from 'react-google-recaptcha';

import { Input } from 'components/shared/Input';
import { Button } from 'components/shared/Button';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { useTranslation } from 'react-i18next';
import { MultiselectComponent } from 'components/shared/MultiselectComponent';
import { Controller } from 'react-hook-form';

import classes from './StepTwo.module.scss';
import { ReCaptchaProps } from 'types/auth';
import { StepTwoProps } from '../RegistrationForm.props';

export const StepTwo: FC<StepTwoProps> = ({
  setStep,
  recaptcha,
  setRecaptcha,
  businessTypes,
  setSelectedBusinessType,
  companySizes,
  setSelectedCompanySize,
  control,
  errors,
  isValid,
  selectedBusinessType,
  selectedCompanySize,
  isStepTwoValid,
  setIsStepTwoValid,
  setGoogleRecaptcha,
}) => {
  const { t } = useTranslation();

  const [companyName, setcompanyName] = useState('');
  const [companyLink, setcompanyLink] = useState('');

  useEffect(() => {
    if (process.env.REACT_APP_DISABLE_RECAPCHA === 'true') {
      if (
        companyName !== '' &&
        companyLink !== '' &&
        companyLink.length <= 50 &&
        isValid
      ) {
        setIsStepTwoValid(true);
      } else {
        setIsStepTwoValid(false);
      }
      return;
    }

    if (isValid && recaptcha !== '') {
      setIsStepTwoValid(true);
    } else {
      setIsStepTwoValid(false);
    }
  }, [isValid, recaptcha, companyName, companyLink]);

  return (
    <>
      <div className={classes['top-fields']}>
        <div className={classes['input-field']}>
          <Controller
            render={({ field }) => (
              <Input
                label={t('Company Name')}
                labelPosition={'left'}
                type={'text'}
                placeholder={t('Enter company name')}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e);
                  setcompanyName(e);
                }}
                error={!!errors?.companyName}
              />
            )}
            control={control}
            name="companyName"
          />
          {errors?.companyName && (
            <TooltipContainer
              text={errors?.companyName?.message}
              customClasses={'kit-ui-block'}
              position={'top'}
              className={classes.error}
            >
              <InfoIcon fill="#F5222D" width="18px" height="18px" />
            </TooltipContainer>
          )}
        </div>
        <div className={classes['input-field']}>
          <Controller
            control={control}
            render={({ field }) => (
              <Input
                label={t('Company Link')}
                labelPosition={'left'}
                type={'text'}
                placeholder={t('Website, LinkedIn, Facebook, etc.')}
                className={classes.email}
                value={field.value}
                error={!!errors?.url}
                onChange={(e) => {
                  field.onChange(e);
                  setcompanyLink(e);
                }}
              />
            )}
            name="url"
          />
          {errors?.url && (
            <TooltipContainer
              text={errors.url?.message}
              customClasses={'kit-ui-block'}
              position={'top'}
              className={classes.error}
            >
              <InfoIcon fill="#F5222D" width="18px" height="18px" />
            </TooltipContainer>
          )}
        </div>
        <div
          className={clsx(classes['input-field'], classes['input-field--full'])}
        >
          <div className={classes['item-label']}>{t('Company size')}</div>
          <Controller
            name="size"
            control={control}
            render={() => (
              <MultiselectComponent
                data={companySizes}
                defaultSelected={selectedCompanySize?.id || companySizes[0]?.id}
                placeholder=""
                setMultiselect={setSelectedCompanySize}
                search={false}
                isCompanySize
              />
            )}
          />
        </div>
        <div
          className={clsx(classes['input-field'], classes['input-field--full'])}
        >
          <div className={classes['item-label']}>{t('Company industry')}</div>
          <Controller
            name="industry"
            control={control}
            render={() => (
              <MultiselectComponent
                data={businessTypes}
                defaultSelected={selectedBusinessType?.id || 1}
                placeholder=""
                setMultiselect={setSelectedBusinessType}
                search={false}
              />
            )}
          />
        </div>
      </div>
      <div className={classes.captcha}>
        {!(process.env.REACT_APP_DISABLE_RECAPCHA === 'true') && (
          <Controller
            render={({ field }) => (
              <ReCAPTCHA
                ref={(e) => setGoogleRecaptcha(e as unknown as ReCaptchaProps)}
                sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTHA_KEY}`}
                onChange={(e: string | null) => {
                  field.onChange(e);
                  e && setRecaptcha(e);
                }}
                hl="en"
                size="normal"
              />
            )}
            name="captcha"
            control={control}
          />
        )}
      </div>
      <div className={classes.submit}>
        <Button
          type="submit"
          className={clsx(classes.button, classes['submit-button'])}
          theme="primary"
          disabled={!isStepTwoValid}
        >
          {t('Create account')}
        </Button>
      </div>
    </>
  );
};
