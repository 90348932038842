import classes from './ProjectDealStages.module.scss';
import { CheckCircleIconPurple } from 'components/shared/Icons/CheckCircleIconPurple';
import { PurpleTimeIcon } from 'components/shared/Icons/PurpleTimeIcon';
import { PurpleEmptyIcon } from 'components/shared/Icons/PurpleEmptyIcon';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CancelledIcon } from 'components/shared/Icons/CancelledIcon';
import { PausedIcon } from 'components/shared/Icons/PausedIcon';
import { DownloadIcon } from 'components/shared/Icons/DownloadIcon';
import { RUSSIAN_LOCALE } from 'utils';
import { formatRuShortDate } from 'tools/formattedDate';
import { DEAL_STAGES } from 'constants/enums';
import { DealStage, ProjectFile, ProjectProps } from 'types/project';
import User from '../../../services/user';
import { useTranslation } from 'react-i18next';

const concatAndRemoveDuplicates = (
  allStages: DealStage[],
  currentStages: DealStage[]
) => {
  // Check for the presence of 'PAUSED' and 'CANCELLED' stages in the current stages
  const hasPausedStage = currentStages?.some(
    (stage) => stage.type === 'PAUSED'
  );
  const hasCancelledStage = currentStages?.some(
    (stage) => stage.type === 'CANCELLED'
  );
  const hasCompletedStage = currentStages?.some(
    (stage) => stage.type === 'COMPLETED'
  );
  // Add stages from 'allStages' only if they are not 'PAUSED' or 'CANCELLED'
  // or such stages exist in 'currentStages'
  return allStages
    ?.filter((stage) => {
      if (stage.type === 'PAUSED' && !hasPausedStage) return false;
      if (stage.type === 'CANCELLED' && !hasCancelledStage) return false;
      return !(stage.type === 'COMPLETED' && !hasCompletedStage);
    })
    .map((stage) => {
      const passedStage = currentStages.find((ps) => ps.name === stage.name);
      return passedStage ? { ...passedStage } : stage;
    });
};

export const ProjectsDealStagesContainer = observer(
  ({ project }: { project: ProjectProps }) => {
    const projectAllStage = project?.deal_stages || [];
    const allStages = project?.all_stages || [];
    const stageArray = [...projectAllStage, ...allStages];
    const { PAUSED, CANCELLED, COMPLETED } = DEAL_STAGES;
    const { t, i18n } = useTranslation();

    const additionalStageIcon = (stageType: string, isTimeIcon?: boolean) => {
      const type = stageType.toLowerCase();
      if (type === PAUSED) {
        return <PausedIcon />;
      } else if (type === CANCELLED) {
        return <CancelledIcon />;
      } else if (isTimeIcon) {
        return <PurpleTimeIcon />;
      } else {
        return <CheckCircleIconPurple />;
      }
    };

    // Add pause or cancel stages to the place where the last active stage was
    const pausedStage = projectAllStage.find(
      (stage: DealStage) => stage?.type?.toLowerCase() === PAUSED.toLowerCase()
    );
    const cancelledStage = projectAllStage.find(
      (stage: DealStage) =>
        stage?.type?.toLowerCase() === CANCELLED.toLowerCase()
    );
    const completedStage = projectAllStage.find(
      (stage: DealStage) =>
        stage?.type?.toLowerCase() === COMPLETED.toLowerCase()
    );
    let lastIndex = -1;
    for (let i = 0; i < projectAllStage.length; i++) {
      if (projectAllStage[i].id !== undefined) {
        lastIndex = i;
      }
    }
    if (pausedStage) {
      projectAllStage.splice(lastIndex + 1, 0, pausedStage);
    }
    if (cancelledStage) {
      const index = pausedStage ? 2 : 1;
      projectAllStage.splice(lastIndex + index, 0, cancelledStage);
    }
    if (completedStage) {
      projectAllStage.splice(lastIndex + 1, 0, completedStage);
    }

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const locale = i18n.language || 'es-ES';

      return date.toLocaleDateString(locale, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      });
    };

    const getStepIcon = (
      stageName: string,
      stageType: string = '',
      id: number = 0,
      planEndDate: string = '',
      factEndDate: string = ''
    ) => {
      const projectFilePath = project?.files?.find(
        (file: ProjectFile) => file?.deal_stage === id
      )?.file;

      const fileName =
        (project?.files?.find((file: ProjectFile) => file?.deal_stage === id)
          ?.file_name as string) ||
        projectFilePath?.match(/\/files\/(.+)/)?.[1];

      const helpText = stageArray?.find(
        (stage: DealStage) => stage?.name === stageName
      )?.description;

      const commercialProposal =
        project.commercial_proposal_file && stageType === 'PROPOSAL_CREATION'
          ? {
              file: project.commercial_proposal_file,
              name:
                project.commercial_proposal_name ||
                project.commercial_proposal_file.split('/').pop(),
            }
          : null;
      const agreement_file =
        project.agreement_file && stageType === 'SIGNING_AGREEMENT'
          ? {
              file: project.agreement_file,
              name:
                project.agreement_name ||
                project.agreement_file.split('/').pop(),
            }
          : null;

      let dateToFormat = null;
      let icon = null;
      let isEmptyStep = false;

      if (planEndDate && factEndDate) {
        dateToFormat = factEndDate;
        icon = additionalStageIcon(stageType);
      } else if (planEndDate && !factEndDate) {
        dateToFormat = planEndDate;
        icon = additionalStageIcon(stageType, true);
        isEmptyStep = true;
      } else {
        icon = <PurpleEmptyIcon />;
        isEmptyStep = true;
      }

      const stepClasses = clsx(classes.step, {
        [classes.step_empty]: isEmptyStep,
      });

      const dateContent = dateToFormat
        ? RUSSIAN_LOCALE
          ? formatRuShortDate(dateToFormat)
          : formatDate(dateToFormat)
        : null;

      return (
        <div className={stepClasses}>
          <div className={clsx(classes.name, classes.past)}>
            <span className={classes.step__date}>{dateContent}</span>
            {icon}
            <div className={classes.step__block}>
              <span>{stageName}</span>
              <p>{helpText}</p>
              {dateToFormat && projectFilePath ? (
                <a
                  className={classes.map__link}
                  title={fileName}
                  rel="noreferrer"
                  onClick={async () => {
                    await User.handleDownloadFile(
                      projectFilePath,
                      fileName as string
                    );
                  }}
                >
                  <div className={classes.map__name}>{fileName}</div>
                  <DownloadIcon />
                </a>
              ) : (
                (commercialProposal || agreement_file) && (
                  <a
                    className={classes.map__link}
                    title={commercialProposal?.name || agreement_file?.name}
                    rel="noreferrer"
                    onClick={async () => {
                      if (commercialProposal?.file || agreement_file?.file) {
                        await User.handleDownloadFile(
                          (commercialProposal?.file ||
                            agreement_file?.file) as string,
                          (commercialProposal?.name ||
                            agreement_file?.name) as string
                        );
                      }
                    }}
                  >
                    <div className={classes.map__name}>
                      {commercialProposal?.name || agreement_file?.name}
                    </div>
                    <DownloadIcon />
                  </a>
                )
              )}
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className={classes.map}>
        {concatAndRemoveDuplicates(
          project?.all_stages,
          project?.deal_stages
        )?.map((stage: DealStage) => {
          if (stage?.id) {
            return getStepIcon(
              stage?.name,
              stage?.type,
              stage.id,
              stage?.plan_end_date,
              stage?.fact_end_date
            );
          } else {
            return getStepIcon(stage?.name, stage.type);
          }
        })}
      </div>
    );
  }
);
