import { AxiosResponse } from 'axios';
import api from './http';

export default class AuthService {
  static async login(
    email: string,
    password: string,
    recaptcha: string,
    gaClientId: string,
    ymClientId: string
  ): Promise<AxiosResponse> {
    return api.post(
      '/login/',
      {
        email,
        password,
        'g-recaptcha-response': recaptcha,
        ga_client_id: gaClientId,
        ym_client_id: ymClientId,
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
  }

  static async logout(refreshToken: string): Promise<AxiosResponse> {
    return api.post('/logout/', {
      'token': refreshToken
    });
  }
}
