import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { languages } from 'constants/languages';
import { useAuthStore } from 'contexts/AuthContext';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getGaClientId, getYmClientId } from 'tools/analytics';
import { Language } from 'types/user';
import * as yup from 'yup';

export interface FormInputs {
  email: string;
  password: string;
  captcha?: string;
}

export const useLogin = () => {
  const isDisableCaptcha = process.env.REACT_APP_DISABLE_RECAPCHA === 'true';
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const authStore = useAuthStore();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const queryParams = new URLSearchParams(location.search);
  const invite = queryParams.get('invite');

  const schema = yup.object().shape({
    email: yup
      .string()
      // .email(t('Enter a valid email address'))
      .required(t('The email address or password is incorrect')),
    password: yup.string().required(t('This field is required')),
    captcha: isDisableCaptcha
      ? yup.string()
      : yup.string().required(t('Captcha is required')),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    setError,
  } = useForm<FormInputs>({ resolver: yupResolver(schema), mode: 'onSubmit' });

  const changeLanguage = (langObj: Language) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (newSearchParams.has('lng')) {
      newSearchParams.delete('lng');
      setSearchParams(newSearchParams);
    }
    i18n.changeLanguage(langObj.lang_code);
    localStorage.setItem('kitGlobalLng', langObj.lang_code);
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data: FormInputs) => {
    const { email, password, captcha } = data;
    const getCookiesConsent = getGaClientId();
    const ymClientId = getYmClientId();

    try {
      const res = await authStore.login(
        email,
        password,
        captcha || '',
        getCookiesConsent || '',
        ymClientId || ''
      );

      const { form_errors, status } = res || {};

      if (form_errors) {
        if (form_errors.email) {
          setError('email', { type: 'server', message: form_errors.email });
        }
        if (form_errors.password) {
          setError('password', {
            type: 'server',
            message: form_errors.password,
          });
        }
        if (form_errors.captcha && !isDisableCaptcha) {
          setError('captcha', {
            type: 'server',
            message: form_errors.captcha,
          });
        }
        if (form_errors.__all__) {
          setError('email', {
            type: 'server',
            message: t(`The email address or password is incorrect`),
          });
        }
      } else if (status) {
        const origin = process.env.REACT_APP_LOGIN_SUCCESS_URL;
        const redirectPath =
          location.state?.from?.pathname && location.state?.from?.search;
        if (redirectPath) {
          navigate(
            location.state?.from?.pathname + location.state?.from?.search
          );
        } else if (origin) {
          navigate(origin);
        }
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setError('email', { type: 'server', message: error.message });
      }
    }
  };

  const defaultLanguage =
    languages[languages.findIndex((lang) => lang.lang_code === i18n.language)]
      ?.id;

  return {
    t,
    i18n,
    changeLanguage,
    defaultLanguage,
    errors,
    onSubmit,
    handleSubmit,
    isDirty,
    isValid,
    control,
    isDisableCaptcha,
    invite,
  };
};
