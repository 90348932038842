import { FileIcon } from 'components/shared/Icons/FileIcon';
import classes from './ProjectDocuments.module.scss';
import { TFunction } from 'i18next';
import { handleDownload } from 'tools/fileDownload';
import {
  ProjectProps,
  ProjectFile,
  ProjectInvoice,
} from '../../../types/project';
import React from 'react';
import { InvoiceIcon } from 'components/shared/Icons/InvoiceIcon';
import { CommercialIcon } from 'components/shared/Icons/CommercialIcon';
import User from '../../../services/user';

type ProjectDocumentsProps = {
  t: TFunction;
  project: ProjectProps | null;
  projectInvoices: ProjectInvoice | null;
};

function ProjectDocuments({
  project,
  t,
  projectInvoices,
}: ProjectDocumentsProps) {
  return (
    <div className={classes['documents-container']}>
      <ul className={classes['documents-list']}>
        {project?.files.map((doc: ProjectFile, index: number) => (
          <li
            key={`${index}-${doc.file}-${doc.size}`}
            className={`${classes['documents-list__item']} 
            ${!doc.description && classes['documents-list__item_center']}`}
          >
            <div className={classes['documents-list__item_icon']}>
              <FileIcon />
            </div>
            <div className={classes['documents-list__item_info']}>
              <div className={classes['documents-list__item_header']}>
                <span
                  className={classes['documents-list__item_label']}
                  onClick={async () =>
                    await User.handleDownloadFile(
                      doc?.file,
                      (doc.file_name || doc.file.split('/').pop()) as string
                    )
                  }
                >
                  {doc.file_name || doc.file.split('/').pop()}
                  <span className={classes['documents-list__item_size']}>
                    {`${(doc.size / 1024 / 1024).toFixed(3)} ${t('MB')}`}
                  </span>
                </span>
              </div>
              {doc.description && (
                <span className={classes['documents-list__item_desc']}>
                  {doc.description}
                </span>
              )}
            </div>
          </li>
        ))}
      </ul>
      {project?.agreement_file && (
        <ul className={classes['documents-list']}>
          <li
            className={`${classes['documents-list__item']} 
            ${!project.agreement_description && classes['documents-list__item_center']}`}
          >
            <div className={classes['documents-list__item_icon']}>
              <FileIcon />
            </div>
            <div className={classes['documents-list__item_info']}>
              <div className={classes['documents-list__item_header']}>
                <span
                  className={classes['documents-list__item_label']}
                  onClick={async () =>
                    User.handleDownloadFile(
                      project.agreement_file as string,
                      (project.agreement_name ||
                        project?.agreement_file?.split('/').pop()) as string
                    )
                  }
                >
                  {project.agreement_name ||
                    project.agreement_file.split('/').pop()}
                  <span className={classes['documents-list__item_size']}>
                    {project.agreement_file_size}
                  </span>
                </span>
              </div>
              {project.agreement_description && (
                <span className={classes['documents-list__item_desc']}>
                  {project.agreement_description}
                </span>
              )}
            </div>
          </li>
        </ul>
      )}
      {project?.commercial_proposal_file && (
        <ul className={classes['documents-list']}>
          <li
            className={`${classes['documents-list__item']} 
            ${!project.commercial_proposal_description && classes['documents-list__item_center']}`}
          >
            <div className={classes['documents-list__item_icon']}>
              <CommercialIcon />
            </div>
            <div className={classes['documents-list__item_info']}>
              <div className={classes['documents-list__item_header']}>
                <span
                  className={classes['documents-list__item_label']}
                  onClick={async () =>
                    User.handleDownloadFile(
                      project?.commercial_proposal_file as string,
                      (project?.commercial_proposal_name ||
                        project?.commercial_proposal_file
                          ?.split('/')
                          .pop()) as string
                    )
                  }
                >
                  {project.commercial_proposal_name ||
                    project.commercial_proposal_file.split('/').pop()}
                  <span className={classes['documents-list__item_size']}>
                    {project.commercial_proposal_file_size}
                  </span>
                </span>
              </div>
              {project.commercial_proposal_description && (
                <span className={classes['documents-list__item_desc']}>
                  {project.commercial_proposal_description}
                </span>
              )}
            </div>
          </li>
        </ul>
      )}
      <ul className={classes['documents-list']}>
        {projectInvoices?.results?.sub_invoices
          ?.filter((invoice) => invoice.file)
          .map((invoice, index) => (
            <li
              key={`${invoice.id}-${index}`}
              className={`${classes['documents-list__item']} 
            ${!invoice.invoice.description && classes['documents-list__item_center']}`}
            >
              <div className={classes['documents-list__item_icon']}>
                <InvoiceIcon />
              </div>
              <div className={classes['documents-list__item_info']}>
                <div className={classes['documents-list__item_header']}>
                  <span
                    className={classes['documents-list__item_label']}
                    onClick={async () =>
                      User.handleDownloadFile(invoice.file as string, '')
                    }
                  >
                    {invoice.invoice.number ||
                      `Invoice #${invoice.serial_number}`}
                    <span className={classes['documents-list__item_size']}>
                      {invoice.file_size}
                    </span>
                  </span>
                </div>
                {invoice.invoice.description && (
                  <span className={classes['documents-list__item_desc']}>
                    {invoice.invoice.description}
                  </span>
                )}
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default ProjectDocuments;
