import classes from './GrowthProgramMain.module.scss';
import ClientList from './components/ClientList';
import RenderCardsList from './components/RenderCardsList';
import ResearchCard from './components/ResearchCard';
import { StickerColor } from 'components/shared/ColorSticker';
import EventCard from './components/EventCard';
import { ColorTagProps } from 'components/shared/ColorTag';
import return_past_img from 'assets/img/GrowthProgramPastNew/return_past_new.jpg';
import expert_past_img from 'assets/img/GrowthProgramPastNew/expert_past_new.jpg';
import secret_past_img from 'assets/img/GrowthProgramPastNew/secret_past_new.jpg';
import influence_img from 'assets/img/GrowthProgramPastNew/influence_past_new.png'
import retail_img from 'assets/img/GrowthProgram/retail.png'
import maximum_img from 'assets/img/GrowthProgram/maximum.png'
import _ from 'lodash';
import SolutionsStand from './components/SolutionsStand';
import { useUserStore } from 'contexts/UserContext';
import CircleIcon from 'components/shared/CircleIcon';
import { FileIcon } from 'components/shared/Icons/FileIcon';
import { Link } from 'components/shared/Link';
import { useAnchorScroll } from 'hooks/useAnchorScroll';
import React, { useEffect } from 'react';
import { link } from 'fs';

interface MockResearch {
  section: string;
  title: string;
  text: string;
  tagColor: StickerColor;
  fileSize: number;
  fileLink: string;
  fileDate: string;
}

interface MockEvent {
  slug: string;
  img: string;
  title: string;
  description: string;
  tags?: Omit<ColorTagProps, 'className'>[];
  date: string;
  location?: string;
  link?: string;
  downloadFile?: string;
}

export default function GrowthProgramMainContainer() {
  const userStore = useUserStore();
  const client = userStore?.client || undefined;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useAnchorScroll();

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <span>{'Программа роста'}</span>
        <Link href="/programma-rosta/rules">
          <CircleIcon className={classes.title__icon} icon={<FileIcon />} />
        </Link>
      </div>

      <div className={classes.content}>
        <ClientList
          client={client}
          bonusBalance={userStore.user?.available_bonus_balance || 0}
        />

        <RenderCardsList
          id={'research'}
          list={[
            {
              section: 'Маркетинг',
              title:'От перфоманс к медиа: куда движется Retail Media',
              text: 'Ключевые тренды в изменении рекламного инвентаря ритейлеров, развитие которых ускорит рост инвестиций в ритейл-медиа — самый быстрорастущий digital-канал. Исследование поможет понять ключевые направления в маркетинговой стратегии, которые дадут максимальный эффект от вложений в ритейл-медиа.',
              tagColor: 'yellow',
              fileDate: '2025-03-17T14:15:29.987Z',
              fileLink:
                '/files/GrowthProgram/KokocGroup_Исследование_трендов_развития_Retail_Media.pdf',
              fileSize: 6249512,
            },
            {
              section: 'Маркетинг',
              title:'Как компании ищут подрядчиков для маркетинга',
              text: 'Мы провели 30+ глубинных интервью с директорами по маркетингу и руководителями, чтобы выяснить, на что бренды обращают внимание при поиске и выборе поставщиков. Исследование охватывает компании малого, среднего и крупного бизнеса России.',
              tagColor: 'yellow',
              fileDate: '2025-03-06T16:55:29.987Z',
              fileLink:
                '/files/GrowthProgram/Как_компании_щут_подрядчиков_для_маркетинга.pdf',
              fileSize: 1331691,
            },
            {
              section: 'Маркетинг',
              title: 'Зачем компаниям продвинутый CRM-маркетинг?',
              text: 'Масштабное исследование Out of Cloud совместно с Calltouch, в котором выяснили, с помощью каких инструментов бизнесу развивать базу пользователей и наращивать выручку в условиях сильного изменения диджитал-ландшафта.',
              tagColor: 'yellow',
              fileDate: '2025-03-06T13:26:29.987Z',
              fileLink: '/files/GrowthProgram/Зачем_компаниям_продвинутый_CRM_маркетинг.pdf',
              fileSize: 3166699,
            },
            {
              section: 'Fashion',
              title:
                'Коды премиальности на Wildberries и Ozon в категории одежды',
              text: 'Документ исследует вопрос, как бренды одежды, растущие в продажах на Wildberries и OZON, формируют образ премиального, дорогого продукта. В документе проанализировано три ключевых элемента: продукт, бренд и коммуникация — для каждого из аспектов выделяются типичные и нетипичные подходы, подкрепленные примерами. Завершается обзор выводами и рекомендациями.',
              tagColor: 'purple',
              fileDate: '2024-11-20T13:26:29.987Z',
              fileLink:
                '/files/GrowthProgram/Коды премиальности на Wildberries и Ozon в категории одежды.pdf',
              fileSize: 10074112,
            },
            {
              section: 'Fashion',
              title: 'Reels-стратегия на трендах (лето 2024)',
              text: 'Анализ контентных трендов в актуальных динамических видеоформатах fashion-брендов летом 2024 года. Описание способов использования этих трендов для создания контентной стратегии бренда. В документе подробно описываются актуальные форматы, темы, примеры и советы по использованию каждого тренда, с разбивкой по степени актуальности: always in trend, established, emerged, declining.',
              tagColor: 'purple',
              fileDate: '2024-11-20T13:26:29.987Z',
              fileLink: '/files/GrowthProgram/Reels-стратегия на трендах.pdf',
              fileSize: 4360192,
            },
            {
              section: 'Фармацевтика',
              title: 'Онлайн-аптеки в России в 2024 г., обзор-прогноз',
              text: 'Аналитический отчет, посвященный рынку онлайн-аптек в России в 2024 году и трендам, которые будут актуальны ближайшей перспективе. В документе рассматриваются ключевые тенденции развития рынка, статистика продаж, характеристики спроса и особенности поведения покупателей, даются рекомендации по подходам к коммуникации и маркетингу.',
              tagColor: 'green',
              fileDate: '2024-11-20T13:26:29.987Z',
              fileLink:
                '/files/GrowthProgram/Онлайн-аптеки в России в 2024 г., обзор-прогноз.pdf',
              fileSize: 665600,
            },
          ]}
          title="Исследования"
          render={(item, index) => {
            const tempItem = item as MockResearch;
            return (
              <ResearchCard
                key={_.uniqueId()}
                currentIndex={index}
                section={tempItem.section}
                title={tempItem.title}
                description={tempItem.text}
                tagColor={tempItem.tagColor}
                fileDate={tempItem.fileDate}
                fileLink={tempItem.fileLink}
                fileSize={tempItem.fileSize}
              />
            );
          }}
        />

        <RenderCardsList
          id={'event'}
          list={[
            {
              slug: 'retail-media-kak-raskryt-potentsial-dlya-brenda',
              img: retail_img,
              title: 'Retail Media — как раскрыть потенциал для бренда?',
              description:
                'Вебинар об актуальных трендах Retail Media и стратегиях повышения эффективности рекламных кампаний',
              tags: [
                { color: 'green', text: 'Онлайн' },
                { color: 'yellow', text: 'Тренды' },
              ],
              date: '17.04.2025 в 13:00',
            },
            {
              slug: 'vyrychka-na-maksimum-strategiya-raboty-s-bazoy',
              img: maximum_img,
              title: 'Выручка на максимум: стратегия работы с базой',
              description:
                'Узнайте, как правильно выстраивать коммуникации с клиентами для роста конверсии и увеличения LTV',
              tags: [
                { color: 'green', text: 'Онлайн' },
                { color: 'purple', text: 'CRM-маркетинг' },
              ],
              date: '20.05.2025 в 14:00',
            },
            {
              slug: 'inflyuens-marketing-2025-effektivnye-strategii',
              img: influence_img,
              title: 'Инфлюенс-маркетинг 2025: эффективные стратегии',
              description:
                'Блогеры как ключ к успеху бренда: тренды, форматы и секреты эффективного сотрудничества за 1 час',
              tags: [
                { color: 'green', text: 'Онлайн' },
                { color: 'purple', text: 'Инфлюенс-маркетинг' },
              ],
              date: '26.03.2025 в 11:00',
              downloadFile: '/files/GrowthProgram/Василий_Ящук_— Инфлюенс_маркетинг.pdf',
            },
            {
              slug: 'sekrety-premium-kak-prodavatsya-dorozhe',
              img: secret_past_img,
              title: 'Секреты premium - как продаваться дороже?',
              description:
                'Роль стратегии бренда и «эффекта premium» в бизнес-результатах компании',
              tags: [
                { color: 'green', text: 'Онлайн' },
                { color: 'purple', text: 'Средний бизнес' },
                {
                  color: 'yellow',
                  text: 'Растущий бизнес',
                },
              ],
              date: '03.03.2025 в 10:30',
              downloadFile: '/files/GrowthProgram/Секреты_premium_как_продавать_дороже.pdf',
            },
            {
              slug: 'ekspertnost-kak-podcherknut-svoi-supersily',
              img: expert_past_img,
              title: 'Экспертность — как подчеркнуть свои «суперсилы»?',
              description:
                'На вебинаре обсудим создание личного бренда с СЕО Pi-Shaped',
              tags: [
                { color: 'green', text: 'Онлайн' },
                { color: 'purple', text: 'Личный бренд' },
              ],
              date: '19.02.2025 в 12:00',
              downloadFile: '/files/GrowthProgram/Экспертность_— как_подчеркнуть_свои_суперсилы.pdf',
            },
            {
              slug: 'inogda-oni-vozvrashchayutsya-a-chto-esli',
              img: return_past_img,
              title: 'Иногда они возвращаются: а что, если?..',
              description:
                'Обсудим основные принципы, которые могут быть полезны компаниям и брендам, в категории которых могут вернуться глобальные игроки',
              tags: [
                { color: 'green', text: 'Онлайн' },
                { color: 'yellow', text: 'Мультикатегорийная' },
              ],
              date: '07.02.2025 в 11:00',
              downloadFile: '/files/GrowthProgram/Иногда они возвращаются.pdf',
            },
          ]}
          title="Мероприятия"
          render={(item) => {
            const tempItem = item as MockEvent;
            return (
              <EventCard
                key={_.uniqueId()}
                imgSrc={tempItem.img}
                title={tempItem.title}
                description={tempItem.description}
                tags={tempItem.tags}
                eventDate={tempItem.date}
                eventLocation={tempItem.location}
                eventLink={tempItem.link}
                buttonName="Подробнее"
                slug={tempItem.slug}
                downloadFile={tempItem.downloadFile}
              />
            );
          }}
        />

        <SolutionsStand title="Витрина списания баллов" />
      </div>
    </div>
  );
}
