import BonusHistoryContainer from 'containers/BonusHistory';
import { useUserStore } from 'contexts/UserContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CLIENT_ROLE } from 'types/user';

export const BonusHistoryPage = () => {
  const navigate = useNavigate();
  const userStore = useUserStore();
  const role = userStore?.client?.permission?.type;

  const allowedRoles = [
    CLIENT_ROLE.ADMIN,
    CLIENT_ROLE.MANAGER,
    CLIENT_ROLE.VIEWER,
  ];

  useEffect(() => {
    if (!role || !allowedRoles.includes(role)) {
      navigate('/programma-rosta/rules/');
    }
  }, [role, allowedRoles, navigate]);

  return <BonusHistoryContainer />;
};