import { useEffect, useState } from 'react';
import { routers } from 'constants/routers';
import { shortDatesByLng } from 'constants/shortDatesByLng';
import { Link } from 'react-router-dom';
import classes from './HotDealsCounter.module.scss';
import fire from 'assets/img/HotDeals/fire.gif';
import fireDisabled from 'assets/img/HotDeals/fire-disabled.png';
import clock from 'assets/img/HotDeals/clock.svg';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { marketPlaceStore } from 'stores/marketPlaceStore';
import { queryParamsHandler } from '../../utils';
import { RUSSIAN_LOCALE } from 'utils';
import { HotDealsCounterProps } from './HotDealsCounter.props';
import { toJS } from 'mobx';

export const HotDealsCounter = ({
  inner,
  small,
  detail,
  className,
}: HotDealsCounterProps) => {
  const { t, i18n } = useTranslation();

  const {
    hourDeals,
    minutesDeals,
    dayDeals,
    featureDeals,
    startDate,
    activeDeals,
  } = toJS(marketPlaceStore.dataTimeHotDeals) || {};

  const [hours, setHours] = useState(hourDeals);
  const [minutes, setMinutes] = useState(minutesDeals);
  const [days, setDays] = useState(dayDeals);
  const [isFeature, setIsFeature] = useState(featureDeals);
  const [start, setStart] = useState(startDate);
  const [isHotDeals, setIsHotDeals] = useState(false);

  const dayShort =
    shortDatesByLng[i18n.language as keyof typeof shortDatesByLng].day;
  const hourShort =
    shortDatesByLng[i18n.language as keyof typeof shortDatesByLng].hour;
  const minuteShort =
    shortDatesByLng[i18n.language as keyof typeof shortDatesByLng].minute;
  const utcDate = new Date(start);
  const dayStart = utcDate.getDate();
  const monthStart = utcDate.toLocaleString(i18n.language, { month: 'long' });
  const yearStart = utcDate.getFullYear();
  const params = queryParamsHandler({ isHot: true });

  useEffect(() => {
    marketPlaceStore.loadHotDeals(params, 'short').then((hotDeals: any) => {
      if (!hotDeals?.data?.results?.length) return;
      setIsHotDeals(true);
    });
  }, []);

  useEffect(() => {
    setHours(hourDeals);
    setMinutes(minutesDeals);
    setDays(dayDeals);
    setIsFeature(featureDeals);
    setStart(startDate);
  }, [hourDeals, minutesDeals, dayDeals, featureDeals, startDate]);

  return activeDeals ? (
    <div translate="no">
      {!inner ? (
        <Link
          to={routers.hotdeals}
          className={clsx(classes.link, className ? className : null)}
        >
          <div className={classes.linkInner}>
            {isFeature ? (
              <div className={classes.fireDisabled}>
                <img src={fireDisabled} alt="" />
              </div>
            ) : (
              <div className={classes.fire}>
                <img src={fire} alt="" />
              </div>
            )}

            <div className={classes.timer}>
              <div className={classes.desc}>
                {isFeature
                  ? t('starts')
                  : RUSSIAN_LOCALE
                    ? t('Ends')
                    : `${t('ends')} ${t('in')}`}
              </div>

              <div className={classes.inner}>
                <span className={classes.time}>
                  {days > 9 ? ` ${days}${dayShort}` : ` 0${days}${dayShort}`}
                </span>{' '}
                :
                <span className={classes.time}>
                  {hours > 9
                    ? ` ${hours}${hourShort}`
                    : ` 0${hours}${hourShort}`}
                </span>{' '}
                :
                <span className={classes.time}>
                  {minutes > 9
                    ? ` ${minutes}${minuteShort}`
                    : ` 0${minutes}${minuteShort}`}
                </span>
              </div>
            </div>
          </div>
        </Link>
      ) : (
        <div
          className={clsx(
            classes.timerInner,
            isFeature && classes.timerInnerFeature,
            small && classes.small,
            detail && classes.detail
          )}
        >
          {isFeature && (
            <span className={classes.feature}>
              {t('Opening time')}:{' '}
              <span className={classes.featureBold}>
                {dayStart} {monthStart} {yearStart}{' '}
              </span>
            </span>
          )}

          {isFeature ? (
            <div className={classes.clock}>
              <img src={clock} alt="" />
            </div>
          ) : (
            <div className={clsx(classes.fire, classes.fireRelative)}>
              <img src={fire} alt="" />
            </div>
          )}

          <div className={clsx(classes.timer, classes.transparent)}>
            {isFeature ? (
              <div className={clsx(classes.inner, classes.rows)}>
                <span className={clsx(classes.time, classes.timeColumn)}>
                  <div className={classes.timeInner}>
                    <span className={classes.timeNumber}>
                      {days > 9 ? ` ${days}` : ` 0${days}`}
                    </span>
                    <span className={classes.timeName}>{t('days')}</span>
                  </div>
                </span>

                <span className={clsx(classes.time, classes.timeColumn)}>
                  <div className={classes.timeInner}>
                    <span className={classes.timeNumber}>
                      {hours > 9 ? ` ${hours}` : ` 0${hours}`}
                    </span>
                    <span className={classes.timeName}>{t('hours')}</span>
                  </div>
                </span>

                <span className={clsx(classes.time, classes.timeColumn)}>
                  <div className={classes.timeInner}>
                    <span className={classes.timeNumber}>
                      {minutes > 9 ? ` ${minutes}` : ` 0${minutes}`}
                    </span>
                    <span className={classes.timeName}>{t('min')}</span>
                  </div>
                </span>
              </div>
            ) : (
              <div className={clsx(classes.inner, classes.row)}>
                <span className={classes.time}>
                  {days > 9 ? ` ${days}${dayShort}` : ` 0${days}${dayShort}`}
                </span>{' '}
                :
                <span className={classes.time}>
                  {hours > 9
                    ? ` ${hours}${hourShort}`
                    : ` 0${hours}${hourShort}`}
                </span>{' '}
                :
                <span className={classes.time}>
                  {minutes > 9
                    ? ` ${minutes}${minuteShort}`
                    : ` 0${minutes}${minuteShort}`}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  ) : (
    <div style={{ display: 'none' }} />
  );
};
