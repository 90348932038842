import React from 'react';
import classes from './Menu.module.scss';
import { MarketplaceIcon } from 'components/shared/Icons/MarketplaceIcon';
import { ProjectIcon } from 'components/shared/Icons/ProjectIcon';
import { FinancesIcon } from 'components/shared/Icons/FinancesIcon';
import { StarAdminIcon } from '../Icons/StarAdminIcon';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { routers } from 'constants/routers';
import { useTranslation } from 'react-i18next';
import { useWindowWidth } from 'hooks/useWindowWidth';
import UiIcon from 'components/shared/Icon';
import { useUserStore } from 'contexts/UserContext';
import { MenuProps } from './Menu.props';
import { CLIENT_ROLE } from 'types/user';
import { RUSSIAN_LOCALE } from 'utils';

export const Menu = ({ active, disabled, closeMenu }: MenuProps) => {
  const { t } = useTranslation();
  const { width } = useWindowWidth();
  const userStore = useUserStore();
  const dashboardProjects = userStore?.dashboardsProjects;
  const showDashboardMenuItem = RUSSIAN_LOCALE || dashboardProjects?.length > 0;

  const userClient = !!userStore?.user?.client;

  const isClientAdmin =
    userStore.client &&
    userStore.client?.permission?.type === CLIENT_ROLE.ADMIN;

  return (
    <>
      {(active || width > 1024) && (
        <ul className={classes.menu}>
          {showDashboardMenuItem && (
            <li className={clsx(classes.item, classes.mobile, classes.market)}>
              <NavLink
                to={routers.dashboard}
                className={(navData) =>
                  clsx(
                    classes.linkWrap,
                    navData.isActive && classes.active,
                    disabled && classes.disabled
                  )
                }
              >
                <UiIcon
                  name="ChartBarIcon"
                  additionalClassName={classes.dashboardIcon}
                />
                <div className={classes.wrap}>
                  <span className={classes.link}>{t('Dashboard')}</span>
                </div>
              </NavLink>
            </li>
          )}

          {width > 1024 && (
            <li className={clsx(classes.item, classes.mobile, classes.market)}>
              <NavLink
                to={routers.solutions}
                onClick={() => {
                  const url = new URL(window.location.href);
                  url.search = '';
                  window.history.replaceState(null, '', url);
                }}
                className={(navData) =>
                  clsx(
                    classes.linkWrap,
                    navData.isActive && classes.active,
                    disabled && classes.disabled
                  )
                }
              >
                <MarketplaceIcon />
                <div className={classes.wrap}>
                  <span className={classes.link}>{t('Solutions')}</span>
                </div>
              </NavLink>
            </li>
          )}

          <li
            className={clsx(
              classes.item,
              classes.inside,
              classes.source,
              active && classes.active
            )}
            data-step-cart-4
            translate="no"
          >
            <NavLink
              to={routers.projects}
              className={(navData) =>
                clsx(
                  classes.linkWrap,
                  classes.projects,
                  navData.isActive && classes.active,
                  disabled && classes.disabled
                )
              }
              onClick={() => closeMenu()}
            >
              <ProjectIcon />
              <div className={classes.wrap}>
                <span className={classes.link}>{t('Projects')}</span>
              </div>
            </NavLink>
          </li>

          {isClientAdmin && (
            <li
              className={clsx(
                classes.item,
                classes.inside,
                classes.finances,
                active && classes.active
              )}
              data-step-projects_details-3
            >
              <NavLink
                to={routers.finances}
                className={(navData) =>
                  clsx(
                    classes.linkWrap,
                    navData.isActive && classes.active,
                    disabled && classes.disabled
                  )
                }
                onClick={() => closeMenu()}
              >
                <FinancesIcon />
                <div className={classes.wrap}>
                  <span className={classes.link}>{t('Finances')}</span>
                </div>
              </NavLink>
            </li>
          )}

          {RUSSIAN_LOCALE && userClient && (
            <li
              className={clsx(
                classes.item,
                classes.inside,
                active && classes.active
              )}
              data-step-projects_details-3
            >
              <NavLink
                to={routers.programma_rosta}
                className={(navData) =>
                  clsx(
                    classes.linkWrap,
                    navData.isActive && classes.active,
                    disabled && classes.disabled
                  )
                }
                onClick={() => closeMenu()}
              >
                <StarAdminIcon />
                <div className={classes.wrap}>
                  <span className={classes.link}>{t('Growth program')}</span>
                </div>
              </NavLink>
            </li>
          )}
        </ul>
      )}
    </>
  );
};
