import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { userStore } from 'stores/userStore';
import {
  BusinessSelectedType,
  FilterProps,
  ServiceSelectedType,
} from 'types/solutions';

export const useCategoryFilters = (
  setServiceTypes: Dispatch<SetStateAction<number[]>>,
  setIsFirstRender: Dispatch<SetStateAction<boolean>>,
  setBusinessTypes: Dispatch<SetStateAction<number[]>>,
  businessTypesChecks: number[],
  serviceTypesOf: ServiceSelectedType[],
  businessTypesOf: BusinessSelectedType[],
  handleFilter: (options: FilterProps) => void,
  isFavorites?: boolean,
  searchParams?: any
) => {
  const [serviceOptions, setServiceOptions] = useState(serviceTypesOf);
  const [serviceSearch, setServiceSearch] = useState('');
  const [businessOptions, setBusinessOptions] = useState(businessTypesOf);
  const [businessSearch, setBusinessSearch] = useState('');

  const user = userStore.user;

  useEffect(() => {
    if (serviceSearch !== '') {
      const newOptions = serviceTypesOf.filter((item: ServiceSelectedType) => {
        if (item.name.toLowerCase().includes(serviceSearch.toLowerCase())) {
          return item;
        }
      });
      setServiceOptions(newOptions);
      return;
    }
    setServiceOptions(serviceTypesOf);
  }, [serviceSearch, serviceTypesOf]);

  useEffect(() => {
    if (businessSearch !== '') {
      const newOptions = businessTypesOf.filter(
        (item: BusinessSelectedType) => {
          if (item.name.toLowerCase().includes(businessSearch.toLowerCase())) {
            return item;
          }
        }
      );

      setBusinessOptions(newOptions);
      return;
    }

    // Если у пользвателя указан business type - тогда мы выводим в списке фильтров его на первое место.
    if (
      user.company_industry &&
      !isFavorites &&
      !searchParams?.currentPage &&
      !searchParams?.businessTypes?.length
    ) {
      const indexUserCompanyIndustry = businessTypesOf.findIndex(
        (item: BusinessSelectedType) => item.id === user.company_industry
      );

      if (indexUserCompanyIndustry > -1) {
        const newOptions = businessTypesOf;
        const [businessType] = businessTypesOf.splice(
          indexUserCompanyIndustry,
          1
        );
        newOptions.unshift(businessType);

        if (!businessTypesChecks.find((item) => item === businessType.id)) {
          if (searchParams?.businessTypesFromUrl?.length) {
            if (
              searchParams?.businessTypesFromUrl?.find(
                (item: any) => item === businessType.id
              )
            ) {
              setBusinessTypes([...businessTypesChecks, businessType.id]);
            } else {
              setBusinessTypes(businessTypesChecks);
            }
          } else {
            setBusinessTypes([...businessTypesChecks, businessType.id]);
          }
        }

        setBusinessOptions(newOptions);
      }
      setIsFirstRender(false);
    } else {
      setBusinessOptions(businessTypesOf);
      setIsFirstRender(false);
    }
  }, [businessSearch, businessTypesOf, searchParams?.businessTypes]);

  const resetSearchService = () => {
    setServiceTypes([]);
    setServiceSearch('');
    handleFilter({ serviceTypes: [] });
  };

  const resetSearchBusiness = () => {
    setBusinessTypes([]);
    setBusinessSearch('');
    handleFilter({ businessTypes: [] });
  };

  return {
    resetSearchService,
    setServiceSearch,
    serviceSearch,
    serviceOptions,
    resetSearchBusiness,
    setBusinessSearch,
    businessSearch,
    businessOptions,
  };
};
