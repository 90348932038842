import { useEffect, useRef, useState } from 'react';
import { StepOne } from './StepOne';
import classes from './RegistrationForm.module.scss';
import clsx from 'clsx';
import { StepTwo } from './StepTwo';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  registrationStepOneSchema,
  registrationStepTwoSchema,
} from 'containers/Account/validationSchemas';
import { getGaClientId, getYmClientId } from 'tools/analytics';
import {
  ReCaptchaProps,
  RegistrationStepOneProps,
  RegistrationStepTwoProps,
} from 'types/auth';
import { ObjectSchema, Schema } from 'yup';
import { BusinessType, CompanySizeType } from 'types/solutions';
import { RegistrationFormProps } from './RegistrationForm.props';
import { useLocation, useNavigate } from 'react-router-dom';
import Service from 'services/service';
import { getLangName } from 'tools/utils';
import { companySizeList } from 'constants/companySizeList';

export const RegistrationForm = ({
  step,
  setStep,
  setSendEmail,
  setSuccessData,
  kidUserInfo,
}: RegistrationFormProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [schema, setSchema] = useState<Schema>(registrationStepOneSchema(t));
  const currentLanguage = localStorage.getItem('kitGlobalLng');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invite = queryParams.get('invite');

  const defaultValues = kidUserInfo
    ? {
        fullName: `${kidUserInfo.first_name} ${kidUserInfo.last_name}`,
        email: kidUserInfo.email,
        phone: kidUserInfo.phone.replace(/^\+/, ''),
        password: '123qwe!@#',
      }
    : {};

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    getValues,
    setValue,
  } = useForm<RegistrationStepTwoProps | RegistrationStepOneProps>({
    resolver: yupResolver(
      schema as ObjectSchema<
        RegistrationStepTwoProps | RegistrationStepOneProps
      >
    ),
    mode: 'all',
    defaultValues,
  });

  useEffect(() => {
    kidUserInfo && setValue('kid_token', kidUserInfo.kid_token);
  }, []);

  useEffect(() => {
    setSchema(
      step === 1 ? registrationStepOneSchema(t) : registrationStepTwoSchema(t)
    );
  }, [step, currentLanguage]);

  const {
    fullName,
    email,
    phone,
    companyName,
    url,
  }: Record<string | number, unknown> = getValues() || {};
  const formRef = useRef(null);
  const [password, setPassword] = useState<string>('');
  const [goals, setGoals] = useState<string[]>([]);
  const [comment, setComment] = useState<string>('');
  const [check, setCheck] = useState(false);
  const [recaptcha, setRecaptcha] = useState('');
  const [passwordErrorDescription, setPasswordErrorDescription] =
    useState<string>('');
  const [companySizes, setCompanySizes] = useState<CompanySizeType[]>([]);
  const [selectedCompanySize, setSelectedCompanySize] =
    useState<CompanySizeType>();
  const [businessTypes, setBusinessTypes] = useState<BusinessType[]>([]);
  const [selectedBusinessType, setSelectedBusinessType] =
    useState<BusinessType>();
  const [isErrorStepOne, setIsErrorStepOne] = useState(false);
  const [checkErrors, setCheckErrors] = useState(false);
  const [isStepTwoValid, setIsStepTwoValid] = useState(false);
  const [googleRecaptcha, setGoogleRecaptcha] = useState<ReCaptchaProps>();

  const setErrorMessage = (field: any, message: string) => {
    setError(field, { type: 'server', message });
  };

  useEffect(() => {
    setSelectedBusinessType(businessTypes[0]);
    setSelectedCompanySize(companySizes[0]);
  }, [i18n.language, businessTypes]);

  const submit = async () => {
    const formData = new FormData();

    const getCookiesConsent = getGaClientId();
    const getYandexId = getYmClientId();

    setCheckErrors(isValid);

    if (kidUserInfo) {
      formData.append('kid_token', kidUserInfo.token);
    }

    formData.append('full_name', fullName as string);
    formData.append('email', email as string);
    formData.append('phone_number', `+${phone}`);

    if (!invite) {
      formData.append(
        'company_industry',
        String(selectedBusinessType?.id) || '1'
      );

      formData.append(
        'company_size',
        (selectedCompanySize?.id || companySizes[0]?.id) as string
      );

      if (companyName) formData.append('company_name', companyName as string);

      if (url) formData.append('company_link', url as string);
    }

    formData.append('password', password);
    if (process.env.REACT_APP_DISABLE_RECAPCHA === 'false') {
      formData.append('g-recaptcha-response', recaptcha);
    }

    formData.append('language', i18n.language);
    if (goals.length > 0) {
      goals.map((goal) => {
        formData.append('goal', goal);
        return null;
      });
    }
    if (comment !== '') {
      formData.append('other_field_value', comment);
    }
    if (check) {
      formData.append('free_consultation', 'on');
    }
    if (getCookiesConsent) {
      formData.append('ga_client_id', getCookiesConsent);
    }
    if (getYandexId) {
      formData.append('ym_client_id', getYandexId);
    }

    const utm_mark = localStorage.getItem('utm_mark');
    if (utm_mark) {
      formData.append('utm_mark', utm_mark);
    }
    const source_page = window.location?.href;
    if (source_page) {
      formData.append('source_page', source_page);
    }

    const requestOptions = {
      method: 'POST',
      body: formData,
      headers: {
        'Platform-Type': process.env.REACT_APP_PLATFORM_TYPE || '',
      },
    };

    fetch(
      `${process.env.REACT_APP_API}/register/${invite ? '?invite=1' : ''}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        const { form_errors } = response || {};
        if (process.env.REACT_APP_DISABLE_RECAPCHA === 'false') {
          googleRecaptcha?.reset();
        }
        if (form_errors) {
          const {
            full_name,
            email,
            phone_number,
            company_name,
            company_link,
            password,
          } = form_errors || {};

          if (full_name) {
            setErrorMessage('fullName', full_name[0]);
          }
          if (email) {
            setErrorMessage('email', email[0]);
          }
          if (phone_number) {
            setErrorMessage('phone', phone_number[0]);
          }
          if (company_name) {
            setErrorMessage('companyName', company_name[0]);
          }
          if (company_link) {
            setErrorMessage('url', company_link[0]);
          }
          if (password) {
            setErrorMessage('password', password[0]);
            setPasswordErrorDescription(password[0]);
          }
        } else {
          setSuccessData(response.data);
          if (invite) navigate('/account/personal');

          setStep(3);
          setSendEmail(email as string);
        }
      });
  };

  useEffect(() => {
    setIsErrorStepOne(!!errors?.email || !!errors?.phone || !!errors?.fullName);
  }, [
    errors?.fullName,
    errors?.email,
    errors?.phone,
    errors?.password,
    errors?.iAgree,
  ]);

  useEffect(() => {
    if (checkErrors) {
      if (isErrorStepOne) {
        setStep(1);
      }
    }
  }, [checkErrors, setStep, isErrorStepOne]);

  const getBusinessTypes = async () => {
    try {
      const response = await Service.getBusinessTypes();
      const data = response?.data?.results?.map((item: BusinessType) => ({
        id: item.id,
        name: getLangName(item, 'name', i18n),
      }));

      setBusinessTypes(data);
    } catch (error) {
      setBusinessTypes([]);
    }
  };

  useEffect(() => {
    getBusinessTypes();
    setCompanySizes(companySizeList(t) as CompanySizeType[]);
  }, [i18n.language]);

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(submit)}
      className={classes.form}
    >
      <div className={clsx(classes.step, step === 1 && classes.active)}>
        <StepOne
          control={control}
          errors={errors}
          password={password}
          setPassword={setPassword}
          setStep={setStep}
          setCompanySizes={setCompanySizes}
          isValid={isValid}
          readonly={!!kidUserInfo}
        />
      </div>
      <div className={clsx(classes.step, step === 2 && classes.active)}>
        <StepTwo
          setStep={setStep}
          check={check}
          setCheck={setCheck}
          setRecaptcha={setRecaptcha}
          recaptcha={recaptcha}
          businessTypes={businessTypes}
          setSelectedBusinessType={setSelectedBusinessType}
          companySizes={companySizes}
          setSelectedCompanySize={setSelectedCompanySize}
          control={control}
          errors={errors}
          isValid={isValid}
          selectedBusinessType={selectedBusinessType}
          selectedCompanySize={selectedCompanySize}
          isStepTwoValid={isStepTwoValid}
          setIsStepTwoValid={setIsStepTwoValid}
          setGoogleRecaptcha={setGoogleRecaptcha}
        />
      </div>
    </form>
  );
};
