import React from 'react';
import classes from '../ShowcaseContainer.module.scss';
import { Header } from 'components/common/Header';
import { Link } from 'react-router-dom';
import { routers } from 'constants/routers';
import { ChevronIcon2 } from 'components/shared/Icons/ChevronIcon2';
import clsx from 'clsx';
import { ShowCaseTimer } from '../Timer';
import { ShowcaseHeaderProps } from '../types';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useUserStore } from 'contexts/UserContext';

export const ShowcaseHeader = ({
  data,
  showText,
  setShowText,
  t,
}: ShowcaseHeaderProps) => {
  const { isSmallTablet } = useWindowWidth();
  const { isLocaleError, isComplete } = useUserStore() || {};
  const showHeader = isComplete && !isLocaleError;
  const subtitle = showText
    ? data?.description
    : `${data?.short_description}...`;

  return (
    <div
      className={classes.header}
      style={{ background: data?.header_background }}
    >
      {showHeader && <Header />}
      <div className={classes.header__contentBlock}>
        <img
          src={isSmallTablet ? data?.image_mobile : data?.image_desktop}
          alt={data?.title || 'Спецпредложение'}
          className={classes.header__contentBlock__bg}
        />
        <Link className={classes.header__back} to={routers.solutions}>
          <ChevronIcon2 width="20px" height="20px" />
          <span className={classes.header__back_text}>{t('Back')}</span>
        </Link>

        <div className={classes.header__title}>{data?.title}</div>
        <div
          className={clsx(
            classes.header__subtitle,
            showText ? classes.expanded : classes.collapsed
          )}
        >
          {subtitle}
        </div>
        {data?.short_description?.length ? (
          <div
            className={classes.header__showMore}
            onClick={() => {
              setShowText(!showText);
              if (isSmallTablet && showText) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }
            }}
          >
            <span>{t('Show more')}</span>
            <ChevronIcon2
              width="20px"
              height="20px"
              className={
                showText ? classes.icon_expanded : classes.icon_collapsed
              }
            />
          </div>
        ) : null}

        <ShowCaseTimer date_diff={data?.date_diff || ''} />
      </div>
    </div>
  );
};
