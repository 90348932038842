import './index.css';
import * as Sentry from '@sentry/react';
import { AuthProvider } from 'contexts/AuthContext';
import { CartProvider } from 'contexts/CartContext';
import { MarketPlaceProvider } from 'contexts/MarketPlaceContext';
import { UserProvider } from 'contexts/UserContext';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { YMaps } from '@pbe/react-yandex-maps';
import { GrowthProgramProvider } from 'contexts/GrowthProgram';

const root = ReactDOM.createRoot(
  document.getElementById('root'), {
    onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
      console.warn('Uncaught error', error, errorInfo.componentStack);
    }),
    onCaughtError: Sentry.reactErrorHandler(),
    onRecoverableError: Sentry.reactErrorHandler(),
  }
);
root.render(
  <AuthProvider>
    <UserProvider>
      <CartProvider>
        <MarketPlaceProvider>
          <GrowthProgramProvider>
            <YMaps>
              <App />
            </YMaps>
          </GrowthProgramProvider>
        </MarketPlaceProvider>
      </CartProvider>
    </UserProvider>
  </AuthProvider>
);
