import React from 'react';
import { BannerProps } from './Banner.props';
import clsx from 'clsx';
import classes from './Banner.module.scss';
import { Button } from '../../shared/Button';
import { useNavigate } from 'react-router-dom';
import UiIcon from '../../shared/Icon';
import { useWindowWidth } from '../../../hooks/useWindowWidth';
import { pushToDataLayer } from 'tools/analytics';

const Banner = ({ handleClose }: BannerProps) => {
  const navigate = useNavigate();
  const handleClick = () => {
    pushToDataLayer('Banner', 'Click', 'clickBlockGrowprogram');
    navigate('/programma-rosta/');
  };

  return (
    <div className={clsx(classes.bannerContainer, classes.banner)}>
      <div className={classes.banner_content} onClick={handleClick}>
        <div className={classes.banner_content_text}>
          Присоединяйтесь к&nbsp;Программе Роста и&nbsp;получайте доступ
          к&nbsp;ресурсам и&nbsp;грантам на&nbsp;развитие бизнеса
        </div>
        <Button className={classes.banner_content_button}>Узнать больше</Button>
      </div>
      <UiIcon
        onClick={handleClose}
        additionalClassName={classes.banner_icon}
        name="CloseIcon"
      />
    </div>
  );
};

export default Banner;
