import { AxiosResponse } from 'axios';
import api from './http';
import { QueryParamTypes } from '../constants/queryParamTypes';
import { ProjectProps } from 'types/project';
import { CLIENT_ROLE } from 'types/user';
import { ShowNotification } from '../tools/showNotification';
import i18next from 'i18next';

export default class User {
  static async getActs(
    clientId: number,
    params?: QueryParamTypes
  ): Promise<AxiosResponse> {
    const url = `/api/v1/client/${clientId}/finances/acts/`;
    return api.get(url, {
      params: {
        page: params?.page,
        page_size: params?.pageSize,
      },
    });
  }

  static async getUser(): Promise<AxiosResponse> {
    return api.get('/api/v1/users/');
  }

  static async getAllOnboardings(): Promise<AxiosResponse> {
    return api.get('/api/v1/onboarding/');
  }

  static async getOnboardingSteps(id: string): Promise<AxiosResponse> {
    return api.get(`/api/v1/onboarding/${id}/`);
  }

  static async completeOnboarding(
    onboardingId: string
  ): Promise<AxiosResponse> {
    return api.post(`/api/v1/onboarding/client/${onboardingId}/`);
  }

  static async sendRegistrationCompleteEmail(): Promise<AxiosResponse> {
    return api.post('/api/v1/auth/send_registration_complete_email/');
  }

  static async changeLanguage(language: string): Promise<AxiosResponse> {
    return api.put('/api/v1/users/', {
      language: language,
    });
  }

  static async getClients(
    page?: number,
    page_size?: number
  ): Promise<AxiosResponse> {
    return api.get('/api/v1/client/', {
      params: {
        page: page?.toString() || '1',
        page_size: page_size?.toString() || '10',
      },
    });
  }

  static async getClientForId(id: number): Promise<AxiosResponse> {
    return api.get(`/api/v1/client/${id}`);
  }

  static async getUserPermissions(
    id: number,
    email: string,
    role: CLIENT_ROLE
  ) {
    return await api.post(`api/v1/client/${id}/permission/`, { email, role });
  }

  static async deleteUserPermissions(
    id: number,
    email: string,
    user_id?: number
  ) {
    return await api.delete(`api/v1/client/${id}/permission/?email=${email}`, {
      data: { user_id, email },
    });
  }

  static async changeUserPermissions(
    id: number,
    role: CLIENT_ROLE,
    email: string,
    user_id?: number
  ) {
    const data: { role: CLIENT_ROLE; email: string; user_id?: number } = {
      role,
      email,
      ...(user_id && { user_id }),
    };

    return await api.patch(`api/v1/client/${id}/permission/`, data);
  }

  static async setOnboardingStatus(
    onboarding_client_passed: boolean
  ): Promise<AxiosResponse> {
    return api.put('/api/v1/users/', { onboarding_client_passed });
  }

  static async getDictionary(field: string): Promise<AxiosResponse> {
    return api.get(`/api/v1/dictionaries/`, {
      params: {
        field,
      },
    });
  }

  static async getProjects(clientId: number): Promise<AxiosResponse> {
    return api.get(`api/v1/client/${clientId}/projects`);
  }

  static async getProject(
    projectId: number
  ): Promise<AxiosResponse<ProjectProps> | void> {
    return api
      .get(`api/v1/projects/${projectId}/`)
      .then((response) => response)
      .catch((error) => {
        if (error.response.status === 404 || error.response.status === 403) {
          return error?.response;
        }
      });
  }

  static async updateProject(projectId: number, goal_name: string) {
    return await api
      .post(`api/v1/projects/${projectId}/project_goal/`, {
        goal: { name: goal_name },
        project: projectId,
      })
      .then((response) => {
        if (response.status === 201) {
          return response?.data;
        }
      });
  }

  static async deleteGoal(projectId: number, goalId?: number) {
    return await api
      .put(`api/v1/projects/${projectId}/project_goal/${goalId}/`)
      .then((response) => {
        if (response.status === 200) {
          return response?.data;
        }
      });
  }

  static async getInvoices(
    clientId: number,
    params?: QueryParamTypes
  ): Promise<AxiosResponse> {
    const url = `/api/v1/client/${clientId}/finances/subinvoices/?${params || 'page=1'}`;
    return api.get(url);
  }

  static async getProjectInvoices(
    page = 1,
    projectId: number
  ): Promise<AxiosResponse> {
    const url = `/api/v1/projects/${projectId}/project_invoices/?page=${page}`;
    return api
      .get(url)
      .then((response) => response)
      .catch((error) => {
        if (error.response.status === 404 || error.response.status === 403) {
          return error?.response;
        }
      });
  }

  static async handleDownloadFile(fileUrl: string, fileName: string) {
    try {
      const response = await api.get(fileUrl, {
        responseType: 'blob',
      });

      const contentDisposition = response?.headers['content-disposition'];

      const match = contentDisposition.match(/filename="?([^"]+)"?/);

      if (match && match[1]) {
        const fileName = match[1];

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}` || 'file');
          document.body.appendChild(link);
          link.click();
          if (link.parentNode) {
            link.parentNode.removeChild(link);
          }
          window.URL.revokeObjectURL(url);
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }
    } catch (error: any) {
      if (error?.response?.status === 404) {
        ShowNotification({
          type: 'error',
          children: i18next.t('File not found'),
        });
      } else {
        ShowNotification({
          type: 'error',
          children: error.message,
        });
      }
    }
  }
}
