import classes from './Finances.module.scss';
import { QueryParamTypes } from 'constants/queryParamTypes';
import { getFormattedDate, getFormattedTime } from 'tools/formattedDate';
import { userStore } from 'stores/userStore';
import { formatBalanceForLocale } from './formatBalanceForLocale';
import { RUSSIAN_LOCALE } from 'utils';
import { TFunction } from 'i18next';
import { DiffDays, Invoice } from 'types';
import { INVOICE_STATUSES } from 'constants/enums';
import clsx from 'clsx';
import { getInvoiceDaysLabel } from '../../tools/numberToWords';

const getPaymentDateMessage = (
  invoice: Invoice,
  paidDate: Date | null,
  cancelledDate: Date | null,
  diffDays: DiffDays,
  t: TFunction,
  due_date: string | null
) => {
  if (
    !due_date ||
    due_date === '' ||
    invoice.status === INVOICE_STATUSES.VOID
  ) {
    return null;
  }
  if (
    (invoice.status === INVOICE_STATUSES.PAID ||
      invoice.status === INVOICE_STATUSES.OVERPAID) &&
    paidDate
  ) {
    return t('Paid {{ dateString }}', {
      dateString: getFormattedDate(paidDate),
    });
  } else if (invoice.status === INVOICE_STATUSES.PARTIALLY_PAID && paidDate) {
    if (RUSSIAN_LOCALE) {
      return `${getInvoiceDaysLabel(Number(diffDays?.difference))} на доплату`;
    } else
      return t('{{ dateString }} days for surcharge', {
        dateString: diffDays.difference,
      });
  } else if (invoice.status === INVOICE_STATUSES.CANCELLED) {
    return t('Cancelled {{dateString}}', {
      dateString: getFormattedDate(cancelledDate),
    });
  } else if (!diffDays.overdue && diffDays.difference) {
    return t('{{ dateString }} days for payment', {
      dateString: diffDays.difference,
    });
  } else if (diffDays.overdue) {
    if (RUSSIAN_LOCALE) {
      return `${getInvoiceDaysLabel(Number(diffDays?.difference))} просрочен`;
    } else
      return t('Overdue by {{ dateString }} days', {
        dateString: diffDays.difference,
      });
  } else if (!diffDays.overdue && !diffDays.difference) {
    return t('Due today');
  }
};

const getInvoiceBtnText = (stat: string) => {
  switch (stat) {
    case 'PAID':
      return 'Paid';
    case 'CANCELLED':
      return 'Cancelled';
    default:
      return 'Pay now';
  }
};

const getInvoiceStatus = (
  status: string,
  t: TFunction
): { text: string; type: string } | undefined => {
  switch (status) {
    case INVOICE_STATUSES.PAID:
      return { text: t('Paid'), type: INVOICE_STATUSES.PAID };
    case INVOICE_STATUSES.PARTIALLY_PAID:
      return {
        text: t('Partially paid'),
        type: INVOICE_STATUSES.PARTIALLY_PAID,
      };
    case INVOICE_STATUSES.CANCELLED:
      return { text: t('Cancelled invoice'), type: INVOICE_STATUSES.CANCELLED };
    case INVOICE_STATUSES.SENT:
      return { text: t('Sent'), type: INVOICE_STATUSES.SENT };
    case INVOICE_STATUSES.VOID:
      return { text: t('Void'), type: INVOICE_STATUSES.VOID };
    case INVOICE_STATUSES.OVERPAID:
      return { text: t('Overpaid'), type: INVOICE_STATUSES.OVERPAID };
    case INVOICE_STATUSES.OVERDUE:
      return { text: t('Overdue'), type: INVOICE_STATUSES.OVERDUE };
  }
};

const getClasses = (status: string) => {
  switch (status) {
    case INVOICE_STATUSES.PAID:
      return classes.table__status__paid;
    case INVOICE_STATUSES.PARTIALLY_PAID:
      return classes.table__status__partially_paid;
    case INVOICE_STATUSES.CANCELLED:
      return classes.table__status__cancelled;
    case INVOICE_STATUSES.SENT:
      return classes.table__status__sent;
    case INVOICE_STATUSES.VOID:
      return classes.table__status__cancelled;
    case INVOICE_STATUSES.OVERPAID:
      return classes.table__status__partially_paid;
    case INVOICE_STATUSES.OVERDUE:
      return classes.table__status__overdue;
    default:
      return '';
  }
};

export const columnsArray = (t: TFunction, isMediaTablet: boolean) =>
  isMediaTablet && RUSSIAN_LOCALE
    ? [
        {
          header: t('Status'),
          accessor: 'status',
          footer: (props: { column: { id: string | number } }) =>
            props.column.id,
        },
        {
          header: t('Issued'),
          accessor: 'issued',
          footer: (props: { column: { id: string | number } }) =>
            props.column.id,
        },
        {
          header: t('Projects'),
          accessor: 'projects',
          footer: (props: { column: { id: string | number } }) =>
            props.column.id,
        },
        {
          header: t('Invoices'),
          accessor: 'invoices',
          footer: (props: { column: { id: string | number } }) =>
            props.column.id,
        },
        {
          header: t('Amount'),
          accessor: 'amount',
          footer: (props: { column: { id: string | number } }) =>
            props.column.id,
        },
        /*{
          header: '',
          accessor: 'action',
          footer: (props: { column: { id: string | number } }) =>
            props.column.id,
        },*/
      ]
    : [
        {
          header: '',
          accessor: 'serialNumber',
          footer: (props: { column: { id: string | number } }) =>
            props.column.id,
        },
        {
          header: t('Projects'),
          accessor: 'projects',
          footer: (props: { column: { id: string | number } }) =>
            props.column.id,
        },
        {
          header: RUSSIAN_LOCALE ? t('Issued') : t('Invoices'),
          accessor: RUSSIAN_LOCALE ? 'issued' : 'invoices',
          footer: (props: { column: { id: string | number } }) =>
            props.column.id,
        },
        {
          header: RUSSIAN_LOCALE ? t('Invoices') : t('Issued'),
          accessor: RUSSIAN_LOCALE ? 'invoices' : 'issued',
          footer: (props: { column: { id: string | number } }) =>
            props.column.id,
        },
        {
          header: RUSSIAN_LOCALE ? t('Status') : t('Amount'),
          accessor: RUSSIAN_LOCALE ? 'status' : 'amount',
          footer: (props: { column: { id: string | number } }) =>
            props.column.id,
        },
        {
          header: RUSSIAN_LOCALE ? t('Amount') : t('Status'),
          accessor: RUSSIAN_LOCALE ? 'amount' : 'status',
          footer: (props: { column: { id: string | number } }) =>
            props.column.id,
        },
      ];
// TODO: Add "action" block when it's needed
/*.concat(
        RUSSIAN_LOCALE
          ? [
              {
                header: '',
                accessor: 'action',
                footer: (props: { column: { id: string | number } }) =>
                  props.column.id,
              },
            ]
          : []
      )*/

export const tableData = (invoices: Invoice[], t: TFunction) =>
  invoices?.map((invoice: Invoice) => {
    const invoiceDate = new Date(invoice?.date);
    const paidDate = invoice.paid_date ? new Date(invoice.paid_date) : null;
    const cancelledDate = invoice.cancelled_date
      ? new Date(invoice.cancelled_date)
      : null;
    const diffDays = invoice.diff_days;
    const invoiceType = getInvoiceStatus(invoice.status, t)?.type;
    const showPayButton =
      invoiceType === INVOICE_STATUSES.SENT ||
      invoiceType === INVOICE_STATUSES.OVERDUE ||
      invoiceType === INVOICE_STATUSES.PARTIALLY_PAID;

    return {
      key: `invoice-${invoice.id}`,
      serialNumber: {
        value: invoice.serial_number,
        className: classes.table__header,
      },
      projects: {
        value: invoice?.project_name,
        className: classes.table__header,
        link: `/projects/${invoice?.project_id}`,
        linkOptions: window.location.href.indexOf('finances') !== -1,
        description: {
          text: `ID ${invoice.project_number}`,
          className: classes.table__text,
        },
      },
      invoices: {
        value: invoice.invoice?.number,
        className: `${classes.table__header} ${classes.table__header__link}`,
        filePath: invoice.file_url,
        description: {
          text: invoice.invoice?.description,
          className: classes.table__text,
          // tooltip: (invoice.invoice?.description?.length || 0) > 167
          tooltip: true
        },
      },
      issued: {
        value: getFormattedDate(invoiceDate),
        className: `${classes.table__header} ${classes.table__header__issued}`,
        // description: {
        //   text: getFormattedTime(invoiceDate),
        //   className: classes.table__text,
        // },
        // time: {
        //   text: getFormattedTime(invoiceDate),
        //   className: 'table__time__finances',
        // },
      },
      status: {
        value: getInvoiceStatus(invoice.status, t)?.text,
        className: clsx(classes.table__status, getClasses(invoice.status)),
        tooltip: invoiceType === INVOICE_STATUSES.OVERPAID,
        overpaidValue:
          invoiceType === INVOICE_STATUSES.OVERPAID &&
          formatBalanceForLocale(
            Number(invoice.total),
            invoice.currency.char_code || userStore.user.currency.char_code,
            invoice.currency?.symbol,
            true
          ),
      },
      amount: {
        value: formatBalanceForLocale(
          Number(invoice.total),
          invoice.currency.char_code || userStore.user.currency.char_code,
          invoice.currency?.symbol,
          true
        ),
        className: classes.table__header,
        description: {
          text: getPaymentDateMessage(
            invoice,
            paidDate,
            cancelledDate,
            diffDays,
            t,
            invoice?.due_date
          ),
          className: classes.table__text,
        },
      },
      action: {
        button: showPayButton && {
          // status: invoice.status,
          text: t('Pay now'),
          // disabled:
          //   invoice.status === 'PAID' ||
          //   (RUSSIAN_LOCALE && new Date(invoice.due_date) < new Date()),
          // cancelled: invoice.status === 'CANCELLED',
          className: classes.table__button,
          filePath: invoice.file,
        },
        // serial_id: invoice.id,
        // project_number: invoice.project?.number,
        // amount: invoice?.total,
        // project_name: invoice.project?.name,
      },
    };
  });

export const queryParamsHandler = ({
  page,
  pageSize,
  quickFilter,
}: QueryParamTypes) => {
  const queryParams = {
    page,
    page_size: pageSize,
    'quick-filter': quickFilter,
  };
  const params = new URLSearchParams();

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value) {
      params.append(key, String(value));
    }
  });
  return params.toString();
};
