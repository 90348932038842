import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import PermissionErrorContainer from 'containers/PermissionError';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { CheckCountry } from 'services/api/checkCountry';
import { RUSSIAN_LOCALE } from 'utils';

const checkAllowCountry = async () => {
  try {
    const response = await CheckCountry();
    if (response.status === 403) {
      return false;
    }
    return true;
  } catch (e: any) {
    if (e?.response && e?.response?.status === 403) {
      return false;
    }
    return true;
  }
};

export function CountryErrorPage() {
  const [isAllow, setAllow] = useState(true);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (RUSSIAN_LOCALE || process.env.REACT_APP_ENABLE_COUNTRY_CHECK !== 'true') {
      setLoading(false);
      return;
    }

    checkAllowCountry().then((allow) => {
      setAllow(allow);
      setLoading(false);
    });
  }, []);

  if (isLoading) {
    return;
  }

  if (!isAllow) {
    return (
      <>
        <TitleAndDescription pageName="403" />

        <PermissionErrorContainer
          isLocaleError={true}
          isPermissionError={false}
        />
      </>
    );
  }

  return <Outlet />;
}
