import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { Checkbox } from 'components/shared/Checkbox/Checkbox';
import { CrossIcon } from 'components/shared/Icons/CrossIcon';
import { PurpleArrowDownIcon } from 'components/shared/Icons/PurpleArrowDownIcon';
import { RocketIcon } from 'components/shared/Icons/RocketIcon';
import { SearchIcon } from 'components/shared/Icons/SearchIcon';
import { ServicesIcon } from 'components/shared/Icons/ServicesIcon';
import { WalletIcon } from 'components/shared/Icons/WalletIcon';
import { Input } from 'components/shared/Input';
import FiltersSkeleton from 'components/shared/Skeleton/FiltersSkeleton/FiltersSkeleton';
import { useTranslation } from 'react-i18next';
import { marketPlaceStore } from 'stores/marketPlaceStore';
import { userStore } from 'stores/userStore';
import { ServiceFilterProps } from 'types/solutions';
import classes from './Filters.module.scss';
import { useCategoryFilter } from './useCategoryFilter';
import { useFilters } from './useFilters';
import { usePriceFilter } from './usePriceFilter';
import { getLangName } from 'tools/utils';
import { OptionType } from 'types/util-types';
import { pushSimpleEventToDataLayer } from 'tools/analytics';

export const Filters = ({
  isLoading,
  serviceTypesChecks,
  businessTypesChecks,
  setServiceTypes,
  setBusinessTypes,
  maxCost,
  priceMin,
  setPriceMin,
  serviceTypesOf,
  businessTypesOf,
  setCurrentPage,
  setBudget,
  budget,
  isFavorites,
  setClearBusinessTypes,
  setIsFirstRender,
  clearSeeMore,
  clearBusinessTypes,
  searchParams,
}: ServiceFilterProps) => {
  const { handleKeyDown, handleBlur } = useFilters();
  const { t, i18n } = useTranslation();

  const handleFilter = () => {
    clearSeeMore();
    setCurrentPage(1);
  };

  const {
    resetSearchService,
    filterLimits,
    serviceLimit,
    setServiceSearch,
    serviceSearch,
    serviceOptions,
    setServiceLimit,
    resetSearchBusiness,
    businessLimit,
    setBusinessSearch,
    businessSearch,
    businessOptions,
    setBusinessLimit,
  } = useCategoryFilter(
    setClearBusinessTypes,
    setServiceTypes,
    setIsFirstRender,
    setBusinessTypes,
    businessTypesChecks,
    serviceTypesOf,
    businessTypesOf,
    handleFilter,
    clearBusinessTypes,
    isFavorites,
    searchParams
  );

  const {
    handleClearPrice,
    minValue,
    handleChangeMinValue,
    handleChangeMinPrice,
    maxValue,
    handleChangeMaxValue,
    handleChangeMaxPrice,
  } = usePriceFilter(
    budget,
    maxCost,
    priceMin,
    setPriceMin,
    setBudget,
    clearSeeMore,
    handleFilter,
    setCurrentPage
  );

  if (isLoading) return <FiltersSkeleton />;

  return (
    <div className={classes.wrapper}>
      <div className={classes.filter}>
        <div className={classes.title}>
          <ServicesIcon /> {t('Types of solutions')}
        </div>

        {serviceTypesChecks?.length > 0 && (
          <Button
            onClick={() => resetSearchService()}
            className={classes.clear}
            theme="icon-default"
          >
            {serviceTypesChecks?.length}
            <CrossIcon />
          </Button>
        )}

        {serviceLimit > filterLimits && (
          <div className={classes['search-field']}>
            <SearchIcon />
            <Input
              value={serviceSearch}
              onChange={setServiceSearch}
              placeholder={t('Search')}
              className={classes.search}
            />
          </div>
        )}

        <div className={classes.choices}>
          {serviceOptions.slice(0, serviceLimit).map((item: OptionType) => (
            <Checkbox
              className={classes.check}
              key={item.id}
              onChange={() => {
                if (serviceTypesChecks.includes(item.id)) {
                  const arr = serviceTypesChecks.filter(
                    (val) => val !== item.id
                  );
                  setServiceTypes(arr);
                  handleFilter();
                  return;
                }
                const arr = [...serviceTypesChecks, item.id];
                setServiceTypes(arr);
                handleFilter();
                pushSimpleEventToDataLayer('clickFilterCheckbox', getLangName(item, 'name', i18n));
              }}
              checked={serviceTypesChecks.includes(item.id)}
              label={getLangName(item, 'name', i18n)}
              disabled={marketPlaceStore.isLoadingServicePages}
            />
          ))}
        </div>

        {serviceOptions.length > filterLimits ? (
          <>
            {serviceLimit === filterLimits ? (
              <Button
                onClick={() => setServiceLimit(serviceOptions.length)}
                className={classes.button}
                theme="text"
              >
                <PurpleArrowDownIcon className={classes['button-icon']} />
                {t('Show all')}
              </Button>
            ) : (
              <Button
                onClick={() => setServiceLimit(filterLimits)}
                className={classes.button}
                theme="text"
              >
                <PurpleArrowDownIcon
                  className={clsx(classes['button-icon'], classes.rotate)}
                />
                {t('Show less')}
              </Button>
            )}
          </>
        ) : null}
      </div>

      <div className={classes.filter}>
        <div className={classes.title}>
          <RocketIcon /> {t('Type of business')}
        </div>

        {businessTypesChecks?.length > 0 && (
          <Button
            onClick={() => resetSearchBusiness()}
            className={classes.clear}
            theme="icon-default"
          >
            {businessTypesChecks?.length}
            <CrossIcon />
          </Button>
        )}

        {businessLimit > filterLimits && (
          <div className={classes['search-field']}>
            <SearchIcon />
            <Input
              value={businessSearch}
              onChange={setBusinessSearch}
              placeholder={t('Search')}
              className={classes.search}
            />
          </div>
        )}

        <div className={classes.choices}>
          {businessOptions.slice(0, businessLimit).map((item: OptionType) => (
            <Checkbox
              className={classes.check}
              key={item.id}
              onChange={() => {
                if (businessTypesChecks.includes(item.id)) {
                  const arr = businessTypesChecks.filter(
                    (val) => val !== item.id
                  );
                  setClearBusinessTypes(true);
                  setBusinessTypes(arr);
                  handleFilter();
                  return;
                }
                const arr = [...businessTypesChecks, item.id];
                setBusinessTypes(arr);
                handleFilter();
                pushSimpleEventToDataLayer('clickFilterCheckbox', getLangName(item, 'name', i18n)); 
              }}
              checked={businessTypesChecks.includes(item.id)}
              label={getLangName(item, 'name', i18n)}
              disabled={marketPlaceStore.isLoadingServicePages}
            />
          ))}
        </div>

        {businessOptions.length > filterLimits ? (
          <>
            {businessLimit === filterLimits ? (
              <Button
                onClick={() => setBusinessLimit(businessOptions.length)}
                className={classes.button}
                theme="text"
              >
                <PurpleArrowDownIcon className={classes['button-icon']} />
                {t('Show all')}
              </Button>
            ) : (
              <Button
                onClick={() => setBusinessLimit(filterLimits)}
                className={classes.button}
                theme="text"
              >
                <PurpleArrowDownIcon
                  className={clsx(classes['button-icon'], classes.rotate)}
                />
                {t('Show less')}
              </Button>
            )}
          </>
        ) : null}
      </div>

      <div className={classes.filter}>
        <div className={classes.title}>
          <WalletIcon /> {t('Budget')}
        </div>

        {(priceMin !== 0 || budget !== 0) && (
          <Button
            onClick={() => handleClearPrice()}
            // className={classes.clearPrice}
            className={classes.clear}
            theme="icon-default"
          >
            {t('Clear all')}
            <CrossIcon />
          </Button>
        )}

        <div className={classes['choices-budget']}>
          <Input
            onKeyDown={(e) => handleKeyDown(e, handleChangeMinPrice)}
            onBlur={(e) => handleBlur(e, handleChangeMinPrice)}
            currency={userStore.user.currency?.symbol}
            onChange={handleChangeMinValue}
            type={'text'}
            placeholder={t('Min price')}
            value={minValue?.toFixed(0).toString()}
            disabled={marketPlaceStore.isLoadingServicePages}
            inputForCurrency={true}
          />

          <Input
            onKeyDown={(e) => handleKeyDown(e, handleChangeMaxPrice)}
            onBlur={(e) => handleBlur(e, handleChangeMaxPrice)}
            currency={userStore.user.currency?.symbol}
            onChange={handleChangeMaxValue}
            labelPosition={'left'}
            type={'text'}
            placeholder={t('Max price')}
            value={maxValue?.toFixed(0)?.toString()}
            disabled={marketPlaceStore.isLoadingServicePages}
            inputForCurrency={true}
          />
        </div>
      </div>
    </div>
  );
};
