import { useUserStore } from 'contexts/UserContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import 'intro.js/introjs.css';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { marketPlaceStore } from 'stores/marketPlaceStore';
import { RUSSIAN_LOCALE } from 'utils';
import './IntroTooltip.css';
import productListClasses from './ProductList/ProductList.module.scss';
import {
  defaultSortParams,
  defaultSortParamsFromUrl,
  options,
  optionsRu,
} from './utils';
import { toJS } from 'mobx';
import {
  BusinessSelectedType,
  ServiceSelectedType,
  SolutionType,
} from 'types/solutions';
import { OptionType } from 'types/util-types';

export const useCardsContainer = (title: string) => {
  const { t } = useTranslation();
  const isFavoritesPage = title === t('Favorites');

  const userStore = useUserStore();
  const mobileScreen = useWindowWidth().isSmallLaptop;

  const allProducts = marketPlaceStore.services;
  const enableHints = allProducts?.length;
  const { currency, language, country } = userStore.user || {};

  const products: SolutionType[] =
    window.location.pathname === '/solutions'
      ? toJS(marketPlaceStore.services)
      : toJS(marketPlaceStore.favoriteServices);

  const pageName =
    window.location.pathname === '/solutions' ? 'Solutions' : 'Favorites';

  const { sortingParams } = toJS(marketPlaceStore) || {};
  const chunkSize = 8;

  const urlParams = new URLSearchParams(location.search);
  const searchParams = {
    currentPage: urlParams.get('page'),
    chunkSize: urlParams.get('page_size'),
    defaultIsFree: urlParams.get('is_free') === 'true',
    defaultIsNew: urlParams.get('is_new') === 'true',
    defaultIsHot: urlParams.get('is_hot') === 'true',
    defaultIsSale: urlParams.get('is_sale') === 'true',
    defaultIsTopUp: urlParams.get('is_top_up') === 'true',
    price_min: urlParams.get('price_min'),
    budget: urlParams.get('price_max'),
    sortParams: urlParams.get('order'),
    serviceTypes: urlParams.get('service_type')?.split(',')?.map(Number),
    businessTypes: urlParams.get('business_type')?.split(',')?.map(Number),
  };

  const [isFree, setIsFree] = useState<boolean>(
    searchParams.defaultIsFree || false
  );
  const [isNew, setIsNew] = useState(searchParams.defaultIsNew || false);
  const [isHot, setIsHot] = useState(searchParams.defaultIsHot || false);
  const [isSale, setIsSale] = useState(searchParams.defaultIsSale || false);
  const [isTopUp, setIsTopUp] = useState(searchParams.defaultIsTopUp || false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [productsToShow, setProductsToShow] =
    useState<SolutionType[]>(products);
  const [serviceTypes, setServiceTypes] = useState<number[]>(
    searchParams?.serviceTypes || []
  );
  const [businessTypes, setBusinessTypes] = useState<number[]>(
    searchParams?.businessTypes || []
  );

  const [serviceTypesOfMarketPlace, setServiceTypesOfMarketPlace] = useState<
    ServiceSelectedType[]
  >([]);
  const [businessTypesOfMarketplace, setBusinessTypesOfMarketPlace] = useState<
    BusinessSelectedType[]
  >([]);
  const [budget, setBudget] = useState(Number(searchParams?.budget) || 0);
  const [priceMin, setPriceMin] = useState<number>(
    Number(searchParams?.price_min) || 0
  );
  const [moreButtonClicked, setMoreButtonClicked] = useState(false);
  const [seeMorePage, setSeeMorePage] = useState(
    Number(searchParams?.currentPage) || 0
  );

  const [recommendedServicesIds, setRecommendedServicesIds] =
    useState<number[]>();
  const [currentPage, setCurrentPage] = useState(
    Number(searchParams?.currentPage) || 1
  );
  const [isMobile, setIsMobile] = useState(false);
  const [pageSize, setPageSize] = useState(8);
  const [sortSelect, setSortSelect] = useState<any>(
    defaultSortParamsFromUrl(t, String(searchParams?.sortParams)) ||
      options(t)[0]
  );

  const [clearBusinessTypes, setClearBusinessTypes] = useState(false);

  const hasRecommendedServices = products.some(
    ({ recommended_service }: SolutionType) => recommended_service?.length
  );

  useEffect(() => {
    (RUSSIAN_LOCALE ? optionsRu(t) : options(t))?.forEach(
      (option: OptionType) => {
        if (option?.id === sortSelect?.id) {
          setSortSelect(option);
        }
      }
    );
  }, [sortSelect?.name, userStore.user?.language?.id]);

  const topFilters = {
    defaultIsFree: isFree,
    defaultIsNew: isNew,
    defaultIsHot: isHot,
    defaultIsSale: isSale,
    defaultIsTopUp: isTopUp,
  };

  const pagination = document.querySelector(
    `.${productListClasses.pagination}`
  );

  useEffect(() => {
    if (pagination || products?.length <= 9) {
      setIsMobile(mobileScreen && window.location.pathname !== '/favorites');
    }
  }, [pagination, mobileScreen]);

  const sortParams = defaultSortParams(sortSelect);

  const savedPageParams = {
    savedMoreButtonClicked: moreButtonClicked,
    savedCurrentPage: currentPage,
    savedChunkSize: chunkSize,
    savedServiceTypes: serviceTypes,
    savedBusinessTypes: businessTypes,
    isFavoritesPage,
    savedBudget: budget,
    savedPriceMin: priceMin,
    savedSortParams: sortParams,
    isFree,
    isNew,
    isHot,
    isSale,
    isTopUp,
    sortSelect,
    clearBusinessTypes: clearBusinessTypes,
  };

  useEffect(() => {
    if (isFavoritesPage) return;
    if (marketPlaceStore.isLoadingServices) return;
    // if (moreButtonClicked) return;
    marketPlaceStore.setSortingParams(savedPageParams);
  }, [
    currentPage,
    chunkSize,
    serviceTypes,
    businessTypes,
    isFavoritesPage,
    budget,
    sortParams,
    isFree,
    isNew,
    isHot,
    isSale,
    isTopUp,
    priceMin,
  ]);

  const clearSeeMore = () => {
    setSeeMorePage(0);
    setMoreButtonClicked(false);
  };

  useEffect(() => {
    setServiceTypesOfMarketPlace(marketPlaceStore.serviceTypes);

    if (userStore.user.company_industry && !isFavoritesPage) {
      marketPlaceStore.isLoadingServices = true;
      const indexUserCompanyIndustry = marketPlaceStore.businessTypes.findIndex(
        (item: BusinessSelectedType) =>
          item.id === userStore.user.company_industry
      );
      if (indexUserCompanyIndustry > -1) {
        setBusinessTypesOfMarketPlace(marketPlaceStore.businessTypes);
      }
    } else {
      setBusinessTypesOfMarketPlace(marketPlaceStore.businessTypes);
    }
    marketPlaceStore.isLoadingServices = false;
  }, [
    country,
    currency?.id,
    language?.id,
    marketPlaceStore?.serviceTypes,
    marketPlaceStore?.businessTypes,
  ]);

  return {
    businessTypes,
    setBusinessTypes,
    isFavoritesPage,
    budget,
    priceMin,
    setPriceMin,
    t,
    pageName,
    isMobile,
    enableHints,
    serviceTypes,
    setServiceTypes,
    serviceTypesOfMarketPlace,
    businessTypesOfMarketplace,
    setBudget,
    sortSelect,
    topFilters,
    pageSize,
    setProductsToShow,
    setCurrentPage,
    setIsFirstRender,
    setClearBusinessTypes,
    clearBusinessTypes,
    setIsHot,
    setIsNew,
    setIsFree,
    isFirstRender,
    currentPage,
    productsToShow,
    setIsTopUp,
    setIsSale,
    chunkSize,
    setSortSelect,
    setPageSize,
    setMoreButtonClicked,
    moreButtonClicked,
    setSeeMorePage,
    seeMorePage,
    clearSeeMore,
    searchParams,
  };
};
