import 'styles/main.scss';
import { useMarketPlaceStore } from 'contexts/MarketPlaceContext';
import { NotFoundPage } from './NotFoundPage';
import { ShowcaseContainer } from '../containers/ShowcaseContainer';

export const ShowcaseCatalogue = () => (
  // const marketPlaceStore = useMarketPlaceStore();
  // const { isErrorServices } = marketPlaceStore || {};

  <ShowcaseContainer />
);
