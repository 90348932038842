import classes from './LinksPolitic.module.scss';
import { useTranslation } from 'react-i18next';
import { defaultLanguage } from 'i18n/i18n';
import { RUSSIAN_LOCALE } from 'utils';
import clsx from 'clsx';

export const LinksPolitic = ({ isAuth }: { isAuth?: boolean }) => {
  const { t, i18n } = useTranslation();

  const links = [
    {
      condition: !RUSSIAN_LOCALE,
      href: `https://kit.global/${
        i18n.language !== defaultLanguage ? `${i18n.language}/` : ''
      }terms/`,
      text: t('Terms of service'),
    },
    {
      condition: RUSSIAN_LOCALE,
      href: `${process.env.REACT_APP_USER_AGREEMENT || ''}`,
      text: t('User agreement'),
    },
    {
      condition: RUSSIAN_LOCALE,
      href: `${process.env.REACT_APP_PRIVACY_POLICY_URL || ''}`,
      text: t('Privacy Policy (single)'),
    },
    {
      condition: !RUSSIAN_LOCALE,
      href: `https://kit.global/${
        i18n.language !== defaultLanguage ? `${i18n.language}/` : ''
      }privacy-policy/`,
      text: t('Privacy Policy (single)'),
    },
    {
      condition: true,
      href: RUSSIAN_LOCALE
        ? process.env.REACT_APP_RU_COOKIES_CONCENT_URL || ''
        : `https://kit.global/${
            i18n.language !== defaultLanguage ? `${i18n.language}/` : ''
          }cookie-policy/`,
      text: t('Cookie Policy_footer'),
    },
    {
      condition: RUSSIAN_LOCALE,
      href: RUSSIAN_LOCALE
        ? process.env.REACT_APP_CLIENT_HELP_URL
        : `https://kit.global/${
            i18n.language !== defaultLanguage ? `${i18n.language}/` : ''
          }/help`,
      text: t('Help'),
    },
  ];

  return (
    <ul
      className={clsx(
        classes['politic-links'],
        RUSSIAN_LOCALE && classes['politic-links__ru']
      )}
    >
      {links.map(
        (link, index) =>
          link.condition && (
            <li key={index}>
              <a
                href={link.href}
                target="_blank"
                className={clsx(
                  classes['politic-links__link'],
                  isAuth && classes['politic-links__link__auth']
                )}
                rel="noreferrer"
              >
                {link.text}
              </a>
            </li>
          )
      )}
    </ul>
  );
};
