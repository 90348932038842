import { QueryParamTypes } from 'constants/queryParamTypes';
import classes from './Acts.module.scss';
import { TFunction } from 'i18next';
import { ProjectActResult } from 'types/project';
import { ACT_STATUSES } from 'constants/enums';
import { getFormattedDate } from 'tools/formattedDate';
import clsx from 'clsx';
import { userStore } from 'stores/userStore';
import { formatBalanceForLocale } from '../formatBalanceForLocale';

// const getActStatus = (status_name: string, t: TFunction) => {
//   switch (status_name) {
//     case ACT_STATUSES.SENT:
//       return { text: t('Sent'), type: ACT_STATUSES.SENT };
//     case ACT_STATUSES.RECEIVED:
//       return { text: t('Received'), type: ACT_STATUSES.RECEIVED };
//     case ACT_STATUSES.REJECTED:
//       return { text: t('Rejected'), type: ACT_STATUSES.REJECTED };
//     case ACT_STATUSES.SIGNED:
//       return { text: t('Signed'), type: ACT_STATUSES.SIGNED };
//     case ACT_STATUSES.CANCELLED:
//       return { text: t('Cancelled'), type: ACT_STATUSES.CANCELLED };
//     case ACT_STATUSES.IN_PROGRESS:
//       return { text: t('In Progress'), type: ACT_STATUSES.IN_PROGRESS };
//   }
// };

const getClasses = (status: ACT_STATUSES) => {
  switch (status) {
    case ACT_STATUSES.SENT:
      return classes.table__status__sent;
    case ACT_STATUSES.RECEIVED:
      return classes.table__status__received;
    case ACT_STATUSES.REJECTED:
      return classes.table__status__rejected;
    case ACT_STATUSES.SIGNED:
      return classes.table__status__signed;
    case ACT_STATUSES.CANCELLED:
      return classes.table__status__cancelled;
    case ACT_STATUSES.IN_PROGRESS:
      return classes.table__status__in_progress;
    default:
      return '';
  }
};

export const columnsArray = (t: TFunction) => [
  {
    header: '',
    accessor: 'serialNumber',
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: t('Projects'),
    accessor: 'project',
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: t('Acts'),
    accessor: 'act',
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: t('Status'),
    accessor: 'status',
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: t('Period'),
    accessor: 'period',
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: t('Sum'),
    accessor: 'amount',
    footer: (props: { column: { id: string | number } }) => props.column.id,
    sortType: 'basic',
  },
];

export const tableData = (acts: ProjectActResult[], t: TFunction) =>
  acts?.map((act, idx) => {
    // const issuedDate = new Date(act.date);
    const issuedDate = act.date ? new Date(act.date) : null;
    const periodStart = act.period_start_date
      ? new Date(act.period_start_date)
      : null;
    const periodEnd = act.period_end_date
      ? new Date(act.period_end_date)
      : null;
    const formattedAmount = formatBalanceForLocale(
      Number(act.amount),
      userStore.user.currency?.char_code || 'RUB',
      userStore.user.currency?.symbol,
      true
    );

    return {
      key: `act-${act.id}`,
      serialNumber: {
        value: idx + 1,
        className: classes.table__header,
      },
      project: {
        value: act.project_name,
        className: clsx(
          classes.table__header,
          classes.table__firstLink,
          classes.table__cell,
          classes.table__cell__project
        ),
        link: `/projects/${act.project_id}`,
        linkOptions: window.location.href.indexOf('finances') !== -1,
        description: {
          text: `ID ${act?.project_number}`,
          className: classes.table__text,
        },
      },
      act: {
        value: `${t('Act')} ${act.number}`,
        filePath: act?.file_url,
        className: clsx(
          classes.table__header,
          classes.table__cell,
          classes.table__cell__act
        ),
        description: {
          text: `${t('issued on')} ${getFormattedDate(issuedDate)}`,
          className: classes.table__text,
        },
      },
      issued: {
        value: getFormattedDate(issuedDate),
        className: clsx(classes.table__header, classes.table__cell),
      },
      status: {
        value: act.status_name,
        className: clsx(
          classes.table__status,
          classes.table__cell,
          getClasses(act.status)
        ),
      },
      period: {
        value: `${periodStart ? getFormattedDate(periodStart) : '—'} - ${periodEnd ? getFormattedDate(periodEnd) : '—'}`,
        className: clsx(
          classes.table__header,
          classes.table__cell,
          classes.table__cell__period
        ),
      },
      amount: {
        value: formattedAmount,
        className: clsx(
          classes.table__header,
          classes.table__cell,
          classes.table__cell__amount
        ),
      },
    };
  });

export const queryParamsHandler = ({
  page,
  pageSize,
  quickFilter,
}: QueryParamTypes) => {
  const queryParams = {
    page,
    page_size: pageSize,
    'quick-filter': quickFilter,
  };
  const params = new URLSearchParams();

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value) {
      params.append(key, String(value));
    }
  });
  return params.toString();
};
