import { getFormattedDate } from 'tools/formattedDate';
import { Link } from '../Link';
import classes from './FileLink.module.scss';
import clsx from 'clsx';
import { handleDownload } from 'tools/fileDownload';

interface FileLinkProps {
  linkName: string;
  link: string;
  size: number;
  date: string;
  className?: string;
  onDownload?: (fileName: string) => void;
}

const FileLink = (props: FileLinkProps) => {
  const onClick = () => {
    handleDownload(props.link, true);

    if (props?.onDownload) {
      const fileName = props.link.split('/').pop() || 'unknown_file';
      props.onDownload(fileName);
    }
  };

  return (
    <div className={clsx(classes.file, props.className)}>
      <Link
        href={props.link || '#'}
        target="_blank"
        className={classes.file__title}
        onClick={onClick}
      >
        {props.linkName}
      </Link>

      <span className={classes.file__size}>
        {(props.size / 1024 / 1024).toFixed(2)}Mb
      </span>

      <div className={classes.dashed} />

      <span className={classes.file__date}>
        {getFormattedDate(new Date(props.date))}
      </span>
    </div>
  );
};

export default FileLink;
