export const shortDatesByLng = {
  en: {
    day: 'd',
    hour: 'h',
    minute: 'm',
  },
  es: {
    day: 'd',
    hour: 'h',
    minute: 'm',
  },
  ru: {
    day: 'д',
    hour: 'ч',
    minute: 'м',
  },
  id: {
    day: 'h',
    hour: 'j',
    minute: 'm',
  },
};
