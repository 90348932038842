import * as React from 'react';
import { SVGProps } from 'react';

export const ClipIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <path
      d="M14.1025 7.76622L8.09206 13.7766C6.72523 15.1435 4.50915 15.1435 3.14231 13.7766C1.77548 12.4098 1.77548 10.1937 3.14231 8.82688L9.15272 2.81647C10.0639 1.90525 11.5413 1.90525 12.4526 2.81647C13.3638 3.7277 13.3638 5.20508 12.4526 6.11631L6.67785 11.891C6.22224 12.3466 5.48354 12.3466 5.02793 11.891C4.57232 11.4354 4.57232 10.6967 5.02793 10.2411L10.0955 5.1735"
      stroke="#F14B4B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
