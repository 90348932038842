import { PurpleTimeIcon } from 'components/shared/Icons/PurpleTimeIcon';
import classes from './AnnounceCard.module.scss';
import { PinIcon } from 'components/shared/Icons/PinIcon';
import { LinkIcon } from 'components/shared/Icons/LinkIcon';
import { Button } from 'components/shared/Button';
import { LeftArrowIcon } from 'components/shared/Icons/LeftArrowIcon';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import React, { forwardRef, useLayoutEffect, useRef} from 'react';
import { CheckIcon } from 'components/shared/Icons/CheckIcon';
import { observer } from 'mobx-react-lite';
import clamp from 'clamp-js';
import { growthStore } from 'stores/growthProgram';
import { ClipIcon } from 'components/shared/Icons/ClipIcon';
import { pushSimpleEventToDataLayer } from 'tools/analytics';

interface AnnounceCardProps {
  title: string;
  eventDate: string;
  eventLocation?: string;
  eventLink?: string;
  nextLink?: string;
  onSubscribeEvent?: () => void;
  disabledEventButton?: boolean;
  isLoadingSubscribe?: boolean;
  downloadFile?: string; 
}

const AnnounceCard = forwardRef<HTMLDivElement, AnnounceCardProps>(
  (props, ref) => {
    const navigate = useNavigate();
    const titleRef = useRef<HTMLSpanElement | null>(null);
    const subscribe = growthStore.currentEventSubscribed;
   
    const extractDateTime = (date: string) => {
      const match = date.match(/(\d{2})\.(\d{2})\.(\d{4})\s?(?:в\s)?(\d{2}):(\d{2})/);
      if (match) {
        const [, day, month, year, hours, minutes] = match.map(Number);
        return new Date(year, month - 1, day, hours, minutes);
      }
      return null;
    };

    const eventStartTime = extractDateTime(props.eventDate);
    const now = new Date();
        const oneHourLater = eventStartTime
          ? new Date(eventStartTime.getTime() + 60 * 60 * 1000)
          : null;

        const isOneHourAfterStart = oneHourLater ? now >= oneHourLater : false;

        const eventDate = isOneHourAfterStart
          ? `Завершено ${props.eventDate.split(' в ')[0]}`
          : props.eventDate;
    

    const handleSubscribe = async () => {
      if (props.onSubscribeEvent) {
        await props.onSubscribeEvent();
      }

      pushSimpleEventToDataLayer('clickButtonJoinEventExpertise', props.title);
    };

    useLayoutEffect(() => {
      if (titleRef.current) {
        clamp(titleRef.current, { clamp: 2 });
      }
    }, []);
    
    const handleDownloadClick = () => {
      if (props.downloadFile) {
        pushSimpleEventToDataLayer('clickLinkDownloadEvent', props.title);
      }
    };

    return (
      <div ref={ref} className={classes.card}>
        <span ref={titleRef} className={classes.card__title}>
          {props.title}
        </span>
        <div className={classes.card__marks}>
          {props.eventDate && (
            <div className={`${classes.card__mark} ${isOneHourAfterStart ? classes["card__mark--completed"] : ''}`}>
              <PurpleTimeIcon />
              {eventDate}
            </div>
          )}
          {props.eventLocation ? (
            <div className={classes.card__mark}>
              <PinIcon />
              {props.eventLocation}
            </div>
          ) : isOneHourAfterStart && props.downloadFile ? (
            <a 
            href={props.downloadFile} 
            download 
            className={classes.card__downloadFile}
            onClick={handleDownloadClick}
            >
              <ClipIcon />
              {'Скачать материалы'}
            </a>
          ) : !isOneHourAfterStart && subscribe && props.eventLink && !props.nextLink? (
            <Link to={props.eventLink || '#'} 
            className={clsx(classes.card__link, classes['card__link--active'])}
            target="_blank"
            rel="noopener noreferrer"
            >
              <LinkIcon />
              {'Подключиться'}
            </Link>
          ) : !isOneHourAfterStart && !props.nextLink ? (
            <span className={classes.card__link}>
             <LinkIcon />
              {'Ссылка на вебинар будет доступна после подтверждения участия'}
            </span>
          ) : null}
        </div>
        {!isOneHourAfterStart && !props.nextLink &&
          (props.disabledEventButton || subscribe ? (
            <Button
              theme="primary-light"
              className={clsx(classes.card__button, classes.disabled)}
            >
              Участвую
              <CheckIcon />
            </Button>
          ) : (
            <Button
              theme={'primary'}
              className={classes.card__button}
              onClick={handleSubscribe}
              disabled={props.isLoadingSubscribe}
            >
              Буду участвовать
            </Button>
          ))}
        {props.nextLink && (
          <Button
            theme={'primary-light'}
            className={clsx(
              classes.card__button,
              classes['card__button--next']
            )}
            onClick={() => navigate(props.nextLink || '#')}
          >
            Следующее мероприятие
            <LeftArrowIcon />
          </Button>
        )}
      </div>
    );
  }
);

export default observer(AnnounceCard);
