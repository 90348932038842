import React from 'react';
import classes from '../ShowcaseContainer.module.scss';
import { TimerProps } from '../types';
import { useTranslation } from 'react-i18next';
import { getTimerLabel } from 'tools/utils';
import { LABEL } from 'constants/enums';

export const ShowCaseTimer = ({ date_diff }: TimerProps) => {
  const { t } = useTranslation();
  if (!date_diff?.length) return null;

  const [days, hours, minutes] = date_diff.split(':');

  const renderDigits = (value: string) =>
    [...value].map((digit, index) => (
      <div key={index} className={classes.timer__elements_item}>
        {digit}
      </div>
    ));

  return (
    <div className={classes.timer}>
      <div className={classes.timer__block}>
        <div className={classes.timer__elements}>{renderDigits(days)}</div>
        <div className={classes.timer__period}>
          {getTimerLabel(Number(days), t, LABEL.DAYS)}
        </div>
      </div>

      <div className={classes.timer__block}>
        <div className={classes.timer__elements}>{renderDigits(hours)}</div>
        <div className={classes.timer__period}>
          {getTimerLabel(Number(hours), t, LABEL.HOURS)}
        </div>
      </div>

      <div className={classes.timer__block}>
        <div className={classes.timer__elements}>{renderDigits(minutes)}</div>
        <div className={classes.timer__period}>
          {getTimerLabel(Number(minutes), t, LABEL.MINUTES)}
        </div>
      </div>
    </div>
  );
};
