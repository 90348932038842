import CircleIcon from '../CircleIcon';
import classes from './IconWithDescription.module.scss';
import clsx from 'clsx';

interface IconWithDescriptionProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  descriptionClassName?: string;
}

const IconWithDescription = ({
  icon,
  title,
  description,
  descriptionClassName,
}: IconWithDescriptionProps) => (
  <div className={classes.wrapper}>
    <CircleIcon icon={icon} />
    <div>
      <span className={classes.title}>{title}</span>
      <span className={clsx(classes.description, descriptionClassName)}>
        {description}
      </span>
    </div>
  </div>
);

export default IconWithDescription;
