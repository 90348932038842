import clsx from 'classnames';
import { Fader } from 'components/shared/Fader';
import FilterTableIcon from 'components/shared/Icons/FilterTableIcon';
import { Pagination } from 'components/shared/Pagination';
import { SORT_BY } from 'constants/queryParamTypes';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import {
  ColumnInstance,
  Row,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { RUSSIAN_LOCALE } from 'utils';
import MobileOptions from './MobileOptions/MobileOptions';
import classes from './Table.module.scss';
import { TableProps } from './TableProps';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { TooltipContainer } from '../Tooltip/TooltipContainer';
import ContainerContent from './ContainerContent';
import { useTranslation } from 'react-i18next';

const Table = ({
  data,
  columns,
  tableActive,
  additionalClassNames,
  id,
  customPageCount,
  getDataByPage,
  defaultPageSize,
  mobileModeTitle,
  filter,
  handleShowModalOnButton,
}: TableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    previousPage,
    prepareRow,
    setPageSize,

    pageCount,
    page,
    nextPage,
    gotoPage,
    state: { pageIndex },
  } = useTable({ columns, data }, useSortBy, usePagination);

  const { isMediaTablet } = useWindowWidth();
  const { t, i18n } = useTranslation();

  const itemsPerPage = isMediaTablet ? 5 : 10;

  const [currentPage, setCurrentPage] = useState(pageIndex + 1);
  const [sortedData, setSortedData] = useState(page);
  const [sortDirections, setSortDirections] = useState<{
    [key: string]: 'desc' | 'asc';
  }>({});

  useEffect(() => {
    setSortedData(page);
  }, [page, filter]);
  const handleSort = useCallback(
    (type: string, columnId?: string) => {
      const currentDirection = sortDirections[columnId || type] || 'asc';
      const newSortDirection: 'desc' | 'asc' =
        currentDirection === 'asc' ? 'desc' : 'asc';
      let sortedPage;

      if (type === SORT_BY.DATE || columnId === 'issued') {
        sortedPage = _.orderBy(
          sortedData,
          [
            (row: Row<{}>) => {
              const dateString = row.values?.issued?.value;
              const parts = dateString.split('.');
              const day = parseInt(parts[0], 10);
              const month = parseInt(parts[1], 10) - 1;
              const year = parseInt(parts[2], 10);
              const date = new Date(year, month, day);
              return date.getTime();
            },
          ],
          [newSortDirection]
        );
      } else if (
        type === SORT_BY.NAME &&
        (columnId === 'projects' ||
          columnId === 'invoices' ||
          columnId === 'action' ||
          columnId === 'status')
      ) {
        sortedPage = _.orderBy(
          sortedData,
          [
            (row: Row<{}>) => {
              if (columnId === 'action') {
                return row.values[columnId]?.button?.text;
              }

              return row.values[columnId || 'projects']?.value;
            },
          ],
          [newSortDirection]
        );
      } else if (columnId === 'amount') {
        sortedPage = _.orderBy(
          sortedData,
          [
            (row: Row<{}>) => {
              const valueToNumber = Number(
                row.values.amount.value.replace(/\s/g, '')
              );

              return Number(valueToNumber);
            },
          ],
          [newSortDirection]
        );
      }

      const newSortDirections = {
        ...sortDirections,
        [columnId || type]: newSortDirection,
      };

      if (sortedPage) setSortedData(sortedPage);
      setSortDirections(newSortDirections);
    },
    [sortDirections, sortedData]
  );

  useEffect(() => {
    setPageSize(itemsPerPage || 10);
  }, []);

  useEffect(() => {
    getDataByPage && getDataByPage(currentPage);
  }, [currentPage, i18n.language]);

  useEffect(() => {
    gotoPage(currentPage - 1);
  }, [currentPage, gotoPage, pageIndex]);

  return (
    <>
      <Fader active={tableActive as boolean}>
        <div
          className={clsx(
            classes.table,
            additionalClassNames && String(additionalClassNames)
          )}
          id={id}
        >
          <MobileOptions
            handleSort={handleSort}
            mobileModeTitle={mobileModeTitle}
          />

          <table {...getTableProps()}>
            <thead>
              {headerGroups?.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className={classes.table__row}
                >
                  <th className={clsx(classes.table__cell)}></th>
                  {headerGroup?.headers?.map((column: ColumnInstance<{}>) => {
                    if (column.render('header')) {
                      return (
                        <th
                          className={classes.table__cell}
                          {...column.getHeaderProps()}
                        >
                          {column.render('header')}

                          <button
                            className={classes.table__sort}
                            onClick={() => {
                              handleSort(SORT_BY.NAME, column.id);
                            }}
                          >
                            <FilterTableIcon />
                          </button>
                        </th>
                      );
                    } else return null;
                  })}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {sortedData?.map((row) => {
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()} className={classes.table__content}>
                    {row?.cells?.map((cell) => {
                      const { value } = cell;

                      const tooltipText = t(
                        'Overpaid {{ name }}. Contact your personal manager',
                        { name: value?.overpaidValue }
                      );

                      return (
                        <td
                          {...cell.getCellProps()}
                          className={clsx(
                            classes.table__cell,
                            value?.tooltip && classes.table__cell__withTooltip,
                            RUSSIAN_LOCALE && id !== 'actsTable' && classes.table__cell__ru,
                            id === 'actsTable' && classes.table__cell__acts
                          )}
                        >
                          {value?.tooltip ? (
                            <TooltipContainer
                              text={tooltipText}
                              position={
                                RUSSIAN_LOCALE && !isMediaTablet
                                  ? 'bottom'
                                  : isMediaTablet
                                    ? 'bottom'
                                    : 'right'
                              }
                              className={
                                classes.table__cell__withTooltip__container
                              }
                              classNameTooltip={
                                classes[
                                  `table__cell__withTooltip__tooltip__${RUSSIAN_LOCALE ? 'bottom' : 'right'}`
                                ]
                              }
                              tooltipInner={
                                classes[
                                  `table__cell__withTooltip__tooltip-inner__${RUSSIAN_LOCALE ? 'bottom' : 'right'}`
                                ]
                              }
                            >
                              <ContainerContent
                                value={value}
                                handleShowModalOnButton={
                                  handleShowModalOnButton
                                }
                              />
                            </TooltipContainer>
                          ) : (
                            <ContainerContent
                              value={value}
                              handleShowModalOnButton={handleShowModalOnButton}
                            />
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Fader>

      {(customPageCount || pageCount) > 1 && (
        <div className={classes.pagination}>
          <Pagination
            currentPage={currentPage}
            totalPages={customPageCount || pageCount}
            setPageNumber={setCurrentPage}
            nextPage={nextPage}
            prevPage={previousPage}
          />
        </div>
      )}
    </>
  );
};

export default Table;
