import { useUserStore } from 'contexts/UserContext';
import 'intro.js/introjs.css';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Filter } from 'types/finances';
import 'containers/CardsContainer/IntroTooltip.css';
import { columnsArray, tableData } from './tableConfig';
import { ProjectActResult } from 'types/project';
import { PaginationResponse } from 'types';
import { useWindowWidth } from 'hooks/useWindowWidth';

export const useActsTable = () => {
  const userStore = useUserStore();
  const { isMediaTablet } = useWindowWidth();
  const { t } = useTranslation();

  const projectActs = userStore.projectActs;
  const initialResults = projectActs.results || [];

  const [acts, setActs] = useState<ProjectActResult[]>(initialResults);
  const [tableActive, setTableActive] = useState(false);
  const itemsPerPage = isMediaTablet ? 5 : 8;
  const [filter, setFilter] = useState<Filter>('all');
  const [customPageCount, setCustomPageCount] = useState<number | undefined>(
    undefined
  );
  const [dataLength, setDataLength] = useState<number>(
    projectActs?.count || initialResults.length
  );

  useEffect(() => {
    if (dataLength) {
      setCustomPageCount(Math.ceil(dataLength / itemsPerPage));
    }
  }, [dataLength, itemsPerPage]);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const getDataByPage = async (currentPage: number | undefined) => {
    const params = {
      page: currentPage,
      pageSize: itemsPerPage,
      quickFilter: filter,
    };
    setTableActive(true);
    try {
      const clientId = userStore.client?.id;
      if (clientId) {
        const response = await userStore.loadProjectActs(clientId, params);
        setDataLength(response?.count);
        setActs(response.results);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTableActive(false);
      handleScrollToTop();
    }
  };

  const getDataByFilter = async (newFilter: Filter) => {
    const params = {
      page: 1,
      pageSize: itemsPerPage,
      quickFilter: newFilter,
    };
    setTableActive(true);
    try {
      const clientId = userStore.client?.id;
      if (clientId) {
        const response = await userStore.loadProjectActs(clientId, params);
        setDataLength(response?.count);
        setActs(response.results);
        setFilter(newFilter);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTableActive(false);
    }
  };

  const columns = useMemo(() => columnsArray(t), [t]);
  const data = useMemo(() => tableData(acts, t), [acts, t]);
  const actsLength = useMemo(() => acts?.length || 0, [acts]);

  return {
    data,
    columns,
    actsLength,
    itemsPerPage,
    getDataByPage,
    customPageCount,
    tableActive,
    getDataByFilter,
  };
};
